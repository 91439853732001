export default {
  namespaced: true,
  state: {
    loading: false,
    token: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    groupId: null,
    companyId: '',
    baseURL: '',
  },
  getters: {
    getToken: (s) => (s.token),
    getUsername: (s) => ({
        firstName: s.firstName,
        lastName: s.lastName,
        email: s.email,
        groupId: s.groupId,
      }),
    getLoading: s => s.loading
  },
  mutations: {
    SET_BASE_URL(state, payload) {
      state.baseURL = payload;
    },
    SET_GROUP_ID(state, payload) {
      state.groupId = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_FIRSTNAME(state, payload) {
      state.firstName = payload;
    },
    SET_LASTNAME(state, payload) {
      state.lastName = payload;
    },
    SET_EMAIL(state, payload) {
      state.email = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    DELETE_USER(state) {
      state.token = '';
      state.firstName = '';
      state.lastName = '';
      state.email = '';
      state.password = '';
    },
  },
  actions: {
    logoutHandler({ commit }) {
      commit('DELETE_USER');
    },
    authHandler({ commit }, info) {
      // commit('SET_TOKEN', token);
      commit('SET_GROUP_ID', info.RoleId);
      commit('SET_FIRSTNAME', info.FirstName);
      commit('SET_LASTNAME', info.LastName);
      commit('SET_EMAIL', info.email);
    },
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload);
    },
  },
};
