<template>
  <div>
    <v-app-bar fixed>
      <v-row>
        <v-container fill-height fluid>
          <v-col cols="2">
            <v-img
              contain
              height="40"
              width="180"
              src="~@/assets/logo_opensky_title_small.png"
            ></v-img>
            <!--          <v-app-bar-title>{{ firstName }} {{ lastName }}</v-app-bar-title>-->
          </v-col>
          <v-col cols="8">
            <v-tabs v-model="tab" centered background-color="grey lighten-4">
              <v-tabs-slider
                :color="tab.slice(1, 15) === 'admin' ? '#E53935' : '#1976d2'"
              />
              <v-tab
                v-for="tab in navigationTab"
                exact
                :to="tab"
                :key="tab.title"
                :style="{
                  color: `${tab.title === 'ADMIN' ? '#E53935' : '#000'}`,
                  display:
                    tab.title === 'ADMIN' && user.groupId !== 1
                      ? 'none'
                      : 'flex',
                  'margin-right':
                    tab.title === 'Tables' && user.groupId !== 1
                      ? 'auto'
                      : tab.title === 'ADMIN'
                      ? 'auto'
                      : '0'
                }"
              >
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-menu bottom min-width="200px" rounded offset-y>
              <template v-slot:activator="{ on }">
                <v-container fill-height fluid>
                  <v-btn text v-on="on">
                    {{ user.firstName }} {{ user.lastName }}
                  </v-btn>
                </v-container>
              </template>
              <v-card>
                <v-list-item-content class="justify-center">
                  <div class="mx-auto text-center">
                    <h3>{{ user.firstName }} {{ user.lastName }}</h3>
                    <p class="caption mt-1">
                      {{ user.email }}
                    </p>
                    <v-divider class="my-3"></v-divider>
                    <v-btn
                      depressed
                      rounded
                      text
                      @click.native="logoutHandler"
                      :to="{ name: 'login' }"
                    >
                      Logout
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </v-col>
        </v-container>
      </v-row>
    </v-app-bar>
    <div class="main">
      <v-container class="my-2" fluid>
        <router-view />
      </v-container>
    </div>

    <v-footer flat padless fixed tile elevation="12">
<!--      <v-card width="100%" class="grey lighten-4 d-flex justify-space-around">-->
<!--        <v-card-text>Made by "Dimpulse"</v-card-text>-->
<!--        <v-card-text class="text-end">2021©</v-card-text>-->
<!--      </v-card>-->
    </v-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MainLayout",
  data: () => ({
    navigation: false,
    firstName: "",
    tab: "",
    navigationTab: [
      { title: "Orders", name: "Orders", color: "green" },
      { title: "Operators", name: "OperatorTable", color: "green" },
      { title: "Tables", name: "Tables", color: "green" },
      { title: "ADMIN", name: "Admin", color: "red" },
      { title: "Options", name: "Options", color: "green" }
    ],
    lastName: "",
    user: {
      firstName: "",
      lastName: "",
      email: "",
      groupId: ""
    }
  }),
  async mounted() {
    const data = this.getUsername();
    this.user.firstName = data.firstName;
    this.user.lastName = data.lastName;
    this.user.email = data.email;
    this.user.groupId = +data.groupId;
  },
  methods: {
    ...mapActions({
      logoutHandler: "global/logoutHandler",
      setLoading: "global/setLoading"
    }),
    ...mapGetters({
      getUsername: "global/getUsername"
    })
  }
};
</script>
