var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.addModal)?_c('v-dialog',{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.modalAddOrder),callback:function ($$v) {_vm.modalAddOrder=$$v},expression:"modalAddOrder"}},[_c('v-card',{staticClass:"pa-4 add-order"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('h2',[_vm._v("Add Order")])])],1),_c('v-row',[_c('v-col',[_c('Autocomplete',{attrs:{"list":_vm.clients,"selectionitem":_vm.addModal.ChoiceClient,"selection":_vm.addModal.CFirstName,"url":"clients","width":"350px","urlname":"Firstname","label":"First Name","rules":_vm.stringRules,"timeout":150},on:{"update:selectionitem":function($event){return _vm.$set(_vm.addModal, "ChoiceClient", $event)},"update:selection":function($event){return _vm.$set(_vm.addModal, "CFirstName", $event)},"updateauto":function($event){return _vm.saveClientInfo(_vm.addModal.ChoiceClient)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Firstname + " " + item.Lastname + " " + item.Email)+" ")])],1)]}}],null,false,478207383)})],1),_c('v-col',[_c('Autocomplete',{attrs:{"list":_vm.clients,"selectionitem":_vm.addModal.ChoiceClient,"selection":_vm.addModal.CLastName,"url":"clients","urlname":"Lastname","label":"Last Name","timeout":150,"rules":_vm.stringRules},on:{"update:selectionitem":function($event){return _vm.$set(_vm.addModal, "ChoiceClient", $event)},"update:selection":function($event){return _vm.$set(_vm.addModal, "CLastName", $event)},"updateauto":function($event){return _vm.saveClientInfo(_vm.addModal.ChoiceClient)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Firstname + " " + item.Lastname)+" ")])],1)]}}],null,false,2969904480)})],1),_c('v-col',[_c('Autocomplete',{attrs:{"list":_vm.clients,"selectionitem":_vm.addModal.ChoiceClient,"selection":_vm.addModal.CPhone,"url":"clients","urlname":"Phone","label":"Phone","rules":_vm.stringRules,"timeout":150},on:{"update:selectionitem":function($event){return _vm.$set(_vm.addModal, "ChoiceClient", $event)},"update:selection":function($event){return _vm.$set(_vm.addModal, "CPhone", $event)},"updateauto":function($event){return _vm.saveClientInfo(_vm.addModal.ChoiceClient)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Phone)+" ")])],1)]}}],null,false,682023959)})],1),_c('v-col',[_c('Autocomplete',{attrs:{"list":_vm.clients,"selectionitem":_vm.addModal.ChoiceClient,"selection":_vm.addModal.CEmail,"url":"clients","urlname":"Email","label":"Email","timeout":150,"rules":_vm.stringRules},on:{"update:selectionitem":function($event){return _vm.$set(_vm.addModal, "ChoiceClient", $event)},"update:selection":function($event){return _vm.$set(_vm.addModal, "CEmail", $event)},"updateauto":function($event){return _vm.saveClientInfo(_vm.addModal.ChoiceClient)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Email)+" ")])],1)]}}],null,false,1620034183)})],1)],1),_vm._l((_vm.addModal.flyInfo),function(item,idx){return _c('v-row',{key:idx,staticClass:"add-order__item"},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('h2',[_vm._v("Fly Direction "+_vm._s(idx + 1))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"EndDateMenu",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date From","placeholder":"Date From","dense":"","outlined":"","readonly":"","autocomplete":"new-password"},model:{value:(item.DateTime),callback:function ($$v) {_vm.$set(item, "DateTime", $$v)},expression:"item.DateTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateItem[idx]),callback:function ($$v) {_vm.$set(_vm.dateItem, idx, $$v)},expression:"dateItem[idx]"}},[_c('v-date-picker',{attrs:{"min":new Date().toISOString().substr(0, 10),"rules":_vm.stringRules},model:{value:(item.DateTime),callback:function ($$v) {_vm.$set(item, "DateTime", $$v)},expression:"item.DateTime"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"mb-5",attrs:{"items":_vm.flyTypeItems,"item-text":"Name","item-value":"Id","dense":"","outlined":""},model:{value:(item.FlyTypeId),callback:function ($$v) {_vm.$set(item, "FlyTypeId", $$v)},expression:"item.FlyTypeId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"subheading",attrs:{"rules":_vm.paxRules,"type":"number","outlined":"","dense":"","min":"0","label":"Pax","placeholder":"Pax"},model:{value:(item.Pax),callback:function ($$v) {_vm.$set(item, "Pax", $$v)},expression:"item.Pax"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Autocomplete',{ref:'fromMenu' + idx,refInFor:true,attrs:{"list":_vm.clients,"selectionitem":_vm.fromMenu[idx],"selection":_vm.fromMenu[idx].Icao,"url":"airports","urlname":"Autocomplete","label":"Icao From","timeout":200,"rules":_vm.stringRules},on:{"update:selectionitem":function($event){return _vm.$set(_vm.fromMenu, idx, $event)},"update:selection":function($event){return _vm.$set(_vm.fromMenu[idx], "Icao", $event)},"updateauto":function($event){return _vm.saveAutocomplete('from', _vm.fromMenu[idx], idx)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Municipality + (item.Name ? (", " + (item.Name)) : "") + (item.Icao ? (", " + (item.Icao)) : " "))+" ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Autocomplete',{ref:'toMenu' + idx,refInFor:true,attrs:{"list":_vm.clients,"selectionitem":_vm.toMenu[idx],"selection":_vm.toMenu[idx].Icao,"url":"airports","urlname":"Autocomplete","label":"Icao To","timeout":200,"rules":_vm.stringRules},on:{"update:selectionitem":function($event){return _vm.$set(_vm.toMenu, idx, $event)},"update:selection":function($event){return _vm.$set(_vm.toMenu[idx], "Icao", $event)},"updateauto":function($event){return _vm.saveAutocomplete('to', _vm.toMenu[idx], idx)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Municipality + (item.Name ? (", " + (item.Name)) : "") + (item.Icao ? (", " + (item.Icao)) : " "))+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"close",on:{"click":function($event){return _vm.deleteItemModal(idx)}}},[_c('span'),_c('span')])],1)}),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"}},[_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.addItemModal}},[_vm._v(" Add ")])],1)],1)],2),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.saveAddOrder(_vm.addModal)}}},[_vm._v(" Save ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }