import axios from 'axios';
import { store } from '@/store/store';

const instance = axios.create({
   baseURL: store.state.global.baseURL,
    withCredentials: true
});
//
// instance.interceptors.request.use(
//   (config) => {
//     config.headers.Authorization = `Bearer ${store.state.global.token}`;
//     return config;
//   },
//   (error) => Promise.reject(error),
// );

export default instance;
