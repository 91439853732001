<template>
  <v-card>
    <component
        :is="component"
        :data="custModal"
        :saveModal="saveModal"
        :cancelModal="cancelModal"
    />
    <v-dialog max-width="50%" v-model="gallery">
      <v-card class="pa-4">
        <v-card-title v-for="el in galleryData" :key="el">
          <v-img class="elevation-12" :src="el"/>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-tabs :id="getPath" centered @change="tabHandler">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="el in tableTab" :key="el.title">
        {{ el.title }}
      </v-tab>
    </v-tabs>
    <Loader v-if="getLoading"/>
    <v-data-table
        v-else
        :items="table"
        :page.sync="page"
        :items-per-page="count"
        :headers="headers"

        :options.sync="options"
        class="mt-4"
        hide-default-footer
    >
      <template v-slot:item.actions="item">
        <div class="d-flex justify-space-around">
          <v-icon @click="modalHandler({ type: 'edit', data: item.item })"
          >mdi-pencil
          </v-icon
          >
          <v-icon @click="rowHandler({ data: item.item })">mdi-delete</v-icon>
        </div>
      </template>
      <template v-slot:item.Images="item">
        <v-img
            contain
            max-width="80px"
            max-height="50px"
            @click="galleryHandler(item.item.Images)"
            :value="item"
            :src="item.item.Images[0].Path || ''"
        />
      </template>
      <template v-slot:item.Birthdate="item">
        {{
          item.item["Birthdate"]
              | getFormattedDate({
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
          })
        }}
      </template>
      <template v-slot:body.prepend>
        <tr>
          <v-btn
              color="blue-grey lighten-4"
              class="my-3 mx-4"
              elevation="0"
              centered
              @click="modalHandler({ type: 'create', data: template })"
          >Add row
          </v-btn>
          <td v-for="(val, key) in template" :key="'search' + key">
            <v-text-field
                class="subheading"
                v-model="query[key]"
                @input="requestHandler"
                type="text"
                :placeholder="key"
            />
          </td>
        </tr>
      </template>
      <template v-slot:item v-if="table[0] === 'no data'">
        <tr>
          <td colspan="5">Sorry, no data</td>
        </tr>
      </template>
    </v-data-table>

    <v-row align="center" class="px-4 mt-0">
      <v-col cols="0" md="2">
        <!--        <span>Showing {{table[0].Id || 0 }}-{{table[table.length - 1].Id || 0}} of {{total}}</span>-->
        <span>Showing {{ this.count }} of {{ total }}</span>
      </v-col>
      <v-col cols="12" md="8">
        <v-pagination
            v-model="page"
            :length="Math.ceil(this.total / this.limit)"
            :total-visible="9"
            @input="paginationHandler"
            class="mt-4"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            class="pt-6"
            v-model="limit"
            :items="[10, 15, 30, 50]"
            label="Items per page"
            dense
            outlined
        />
      </v-col>
    </v-row>
    <v-btn  @click="logHandler()">Log</v-btn>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loader from "../components/Loader";
import {getFormattedDate} from "../filters";
import AircraftModal from "../components/ModalWindows/AircraftModal";
import AircraftTypesModal from "../components/ModalWindows/AircraftTypesModal";
import AircraftClassModal from "../components/ModalWindows/AircraftClassModal";
import AirportsModal from "../components/ModalWindows/AirportsModal";
import OperatorModal from "../components/ModalWindows/OperatorModal";
import FlyTypeModal from "@/components/ModalWindows/FlyTypeModal";

export default {
  name: "Table",
  filters: {
    getFormattedDate
  },

  data: () => {
    return {
      // sortBy: '',
      // sortDesc: false,
      options: {},
      custModal: {
        data: null,
        template: null
      },
      component: null,
      table: [],
      tableTab: [
        {
          title: "Aircraft classes",
          path: "aircrafts/classes",
          modalDialog: AircraftClassModal
        },
        {
          title: "Aircraft types",
          path: "aircrafts/types",
          modalDialog: AircraftTypesModal
        },
        {title: "Aircrafts", path: "aircrafts", modalDialog: AircraftModal},
        {
          title: "Flight type",
          path: "/orders/flyType",
          // modalDialog: ClientStatusModal
          modalDialog: FlyTypeModal
        },
        // { title: "Clients", path: "clients", modalDialog: ClientModal },
        {title: "Operators", path: "operators", modalDialog: OperatorModal},
        // {title: 'Order status', path: ''},
        {title: "Order type", path: "orders/type"},
        {
          title: "Worldwide airports",
          path: "airports",
          modalDialog: AirportsModal
        }
      ],
      rules: {
        max(maxNum) {
          return v => (v || "").length <= maxNum || "Max exceeded";
        }
      },
      dateMenu: null,
      birthday: null,
      query: {},
      data: {},
      template: {},
      page: 1,
      limit: 10,
      total: 0,
      count: 0,
      pagination: {
        orderBy: "id",
        sort: "ASC"
      },
      modal: false,
      modalData: {},
      gallery: false,
      galleryData: [],
      allStatus: null
    };
  },
  components: {
    Loader,
    AircraftModal,
    AircraftTypesModal,
    AircraftClassModal,
    AirportsModal,
    OperatorModal
  },
  watch: {
    async watchedStates() {
      this.page = 1;
      this.query = {};
      this.total = 0;
      this.setLoading(true);
      await this.requestHandler("new");
      this.setLoading(false);
    },
    query: {
      handler: function () {
        this.page = 1;
      },
      deep: true
    },
    options: {
      async handler () {
        // this.getDataFromApi()
        console.log("options", "sortBy", this.options.sortBy[0], "sortDesc", this.options.sortDesc[0])
        this.setLoading(true);
        this.requestHandler();
        this.setLoading(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('global', ['getLoading']),
    ...mapGetters('table', ['getPath']),
    
    headers() {
      const headers = [
        {
          text: "Actions",
          width: "1%",
          value: "actions",
          sortable: false,
          class: "blue-grey title lighten-4"
        }
      ];
      for (const el in this.template) {
        headers.push({
          text: el,
          value: el,
          width: `${(el.length + 18) * 7}px`,
          sortable: true,
          class: "blue-grey title lighten-4"
        });
      }
      return headers;
    },
    modalItems() {
      const obj = {};
      for (const el in this.modalData.data) {
        if (el === "Images") {
          obj[el] = 12;
        } else {
          obj[el] = 3;
        }
      }
      return obj;
    },
    tablePath() {
      return this.tableTab[this.getPath].path
    },
    tableModal() {
      return this.tableTab[this.getPath].modalDialog
    },
    watchedStates() {
      return this.limit + this.tablePath;
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.requestHandler("new");
    this.setLoading(false);
  },
  methods: {
    ...mapActions({
      setTab: "table/tableHandler",
      setLoading: "global/setLoading"
    }),
    logHandler() {
      console.log("logHandler", this.sortBy, this.sortDesc)
    },
    async tabHandler(path) {
      this.options.sortBy[0] = undefined;
      this.options.sortDesc[0] = false;
      this.setTab({path});
      // this.setLoading(true)
      // await this.requestHandler('new')
      // this.setLoading(false)
    },
    
    saveModal(type, data) {
      this.rowHandler({type, data});
    },

    cancelModal() {
      this.component = null;
    },

    modalHandler({type, data}) {
      this.component = this.tableModal;
      this.custModal = Object.assign(
          {},
          JSON.parse(JSON.stringify({type, data}))
      );
      // this.modalData = obj;
      // this.modal = !this.modal;
      // this.birthday = data["Birthdate"].substr(0, 10).split("/").join("-");
    },
    galleryHandler(data) {
      this.gallery = true;
      this.galleryData = data;
    },
    paginationHandler(val) {
      this.page = val;
      this.requestHandler();
    },
    async requestHandler(type) {
      try {
        const query = await this.queryHandler();
        const result = await this.$api.request.getTable(
            `${this.tablePath}?${query}&Page=${this.page}&Limit=${this.limit}`
        );
        if (result.data.Data !== null) {
          this.table = result.data.Data;
        } else {
          this.table[0] = "no data";
        }
        // if (this.tablePath == "clients") {
        //   await this.$api.request.getTable("/clients/status").then((resp) => {
        //     this.allStatus = resp.data.Data;
        //   });
        // }
        if (type === "new") {
          if (result.data.Template) {
            this.template = result.data.Template.BaseFields;
          }
        }
        if (result.data.Pagination) {
          const pagination = result.data.Pagination;
          this.page = pagination.Page;
          this.limit = pagination.Limit;
          this.count = pagination.Count;
          this.pagination.orderBy = pagination.OrderBy;
          this.pagination.sort = pagination.OrderSort;
          this.total = pagination.Total;
        }
      } catch (e) {
        this.$toast.error("Error");
      }
    },
    async rowHandler({type, data}) {
      try {
        // data["Birthdate"] = this.birthday + "T10:00:00.000000Z";
        // if (data["ClientStatusId"] === 1) {
        //   data["ClientStatus"] = "DEFAULT";
        // } else {
        //   data["ClientStatus"] = "VIP";
        // }
        // data["Id"] = +data["Id"];
        if (type === "create") {
          await this.$api.request.createRow({url: this.tablePath, data});
        } else if (type === "edit") {
          await this.$api.request.editRow({url: this.tablePath, data});
        } else {
          await this.$api.request.deleteRow({
            url: this.tablePath,
            data: {Id: data.Id}
          });
        }
        this.component = null;
        await this.requestHandler();
        this.$toast.success("Successfully operation");
      } catch (e) {
        this.$toast.error("Error");
      }
    },
    queryHandler() {
      const arr = [];
      for (let i in this.query) {
        if (this.query[i] !== "") {
          arr.push(i + "=" + this.query[i]);
        } else {
          delete arr[i];
        }
      }
      
      if (this.options.sortBy[0] != "" && this.options.sortBy[0] != undefined) {
        switch (this.getPath) {
          case 1:  //Aircraft types
            switch (this.options.sortBy[0]){
              case "ICAO": 
                arr.push("OrderBy=Icao");
                break;
              case "Air_Class": 
                arr.push("OrderBy=AircraftClass");
                break;
              case "Alt_Names": 
                arr.push("OrderBy=AlternativeNames");
                break;
              case "price_per_hour": 
                arr.push("OrderBy=PricePerHour");
                break;
              default: 
                arr.push("OrderBy="+this.options.sortBy[0])          
                break;
            }
          break;
          case 2: 
            switch(this.options.sortBy[0]){ // Aircraft
              case "Country": 
                arr.push("OrderBy=country");
                break;
              case "Operator": 
                arr.push("OrderBy=operator_name");
                break;  
              case "OperatorId": 
                arr.push("OrderBy=operator_id");
                break;  
              case "Homebase": 
                arr.push("OrderBy=homebase");
                break;  
              case "Class": 
                arr.push("OrderBy=aircraft_class");
                break;                  
              case "Type": 
                arr.push("OrderBy=aircraft_type");
                break;    
              case "Reg_num": 
                arr.push("OrderBy=registration_number");
                break;    
              case "PAX": 
                arr.push("OrderBy=max_passengers");
                break;         
              case "Year": 
                arr.push("OrderBy=year_of_production");
                break; 
              case "PriceFact": 
                arr.push("OrderBy=price_fact");
                break;     
              case "PriceWeightedAverage": 
                arr.push("OrderBy=price_weighted_average");
                break;                 
            }            
          break;
          case 6: 
            switch(this.options.sortBy[0]){
              case "Country": 
                arr.push("OrderBy=IsoCountry");
                break;  
              case "Icao": 
                arr.push("OrderBy=Icao");
                break;  
              case "Class": 
                arr.push("OrderBy=aircraft_class");
                break;                                
              default: 
                arr.push("OrderBy="+this.options.sortBy[0])          
                break;                               
            }
          break;
          default: 
            arr.push("OrderBy="+this.options.sortBy[0])          
            break;
        }
        
      }
      switch (this.options.sortDesc[0]){
        case true: arr.push("OrderSort=desc");
        break;
        case false: arr.push("OrderSort=asc");
        break;
      }

      return arr.join("&");
    }
  }
};
</script>

<!--<style>-->
<!--virtual-scroll-table {-->
<!--  max-height: 400px;-->
<!--  overflow: auto;-->
<!--}-->
<!--</style>-->

<style lang="scss" scoped>
.virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}
</style>
