<template>
  <div>
    <div class="images__container">
      <div class="images__container_btn">
        <a v-if="preview.length > 0 || defaultItem.length > 0" :href="defaultItem || preview" target="_blank"/>
        <img v-if="preview.length > 0 || defaultItem.length > 0" :src="defaultItem || preview" :alt="title">
        <v-upload-file
            @deleteFile="deleteClick"
            :name="name"
            :arr-files="arrFile"
            :preview="preview || defaultItem"
            @loadingFiles="clickFile"/>
      </div>
      <div class="images__container_title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
import VUploadFile from "@/components/OperatorCabinet/components/VUploadFile";

export default {
  name: "VBlockActionsImages",
  components: {VUploadFile},
  props: {
    watchProp: {type: String, required: true},
    defaultItem: {type: String, default: ''},
    arrFile: {type: Array, required: true},
    title: {type: String, required: true},
    name: {type: String}
  },
  watch: {
    watchProp() {
      this.preview = ''
    }
  },
  data() {
    return {
      preview: ''
    }
  },
  methods: {
    clickFile(arr, name) {
      const reader = new FileReader();
      const that = this
      this.$emit('addFile', arr, name)
      reader.onloadend = function () {
        that.preview = reader.result;
      }
      if (arr.length > 0) {
        reader.readAsDataURL(arr[0]);
      } else {
        this.preview = ''
      }
    },
    deleteClick(name) {
      this.$emit('deleteFile', name)
      this.preview = ''
    }
  },
}
</script>

<style scoped lang="scss">

.images__container {
  &_btn {
    position: relative;
    border: 1px dashed $secondaryDarkColor;
    border-radius: 8px;
    height: 180px;
    overflow: hidden;
    width: 100%;
    min-width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: transparent;
      z-index: 2;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  &_title {
    @include body-16-500;
    text-align: center;
  }

  //&_files {
  //  padding-left: 0;
  //
  //  & > *:not(:last-child) {
  //    margin-bottom: 8px;
  //  }
  //
  //  .file {
  //    border: 1px solid $secondaryLighterColor;
  //    border-radius: 8px;
  //    padding: 8px;
  //    color: $secondaryDarkestColor;
  //    position: relative;
  //    display: flex;
  //    justify-content: flex-start;
  //    align-items: center;
  //
  //    &::before {
  //      content: '';
  //      height: 24px;
  //      width: 24px;
  //      border-radius: 6px;
  //      margin-right: 16px;
  //      background: url(../assets/img/document.svg) center no-repeat;
  //    }
  //
  //    img {
  //      position: absolute;
  //      right: 8px;
  //      top: 30%;
  //    }
  //  }
  //}
}
</style>
