<template>
   <!-- <div>
   <table style="width:100%">
      <tr>
        <td>Alfreds Futterkiste</td>
        <td>Maria Anders</td>
        <td>Germany</td>
      </tr>
      <tr>
        <td>Centro comercial Moctezuma</td>
        <td>Francisco Chang</td>
        <td>Mexico</td>
      </tr>
    </table>
  </div> -->
  <div class="offers"> 
     <div class="offers__header">
      AIRCRAFT OFFERS
    </div>
    <div >
      <!-- <img style="height: 200px; width: 200px;" :src="data.uriI"> -->
    </div>
    <div>
      <div v-if="num == 1" class="row " 
      style="width:100%; padding: 0px; 
        margin: 0 10px; font-size: 10px; height: 42px;">
        <div class="" style="width:99px; display: flex; align-items: center;">
          Offers: {{ length}}
        </div>
        <div class="" style="width:438px; display: flex; align-items: center;">
          Price Range:<span style="font-weight: 600;">&nbsp;${{priceMin}} - ${{priceMax}}</span>
        </div>
      </div>
    </div>
    <div>      
      <hr style="border: 0.5px solid rgb(221, 221, 221);">
      <div v-for="(v, i) in tableData" :key="i">
        <div class="rowao bsol" >
          <div class="rowi bdot" >            
            <div class="rowtw bdot" >
              <div class="wtitle">
                <span style="font-weight: 600;">OPTION {{num + i}}:</span>
              </div>
              <div class="wvalue">
                <span style="font-weight: 600;">{{v.Type}}</span>
              </div>
            </div>
            <div class="rowtw bdot">
              <div class="wtitle">
                <span >Aircraft Class:</span>
              </div>
              <div class="wvalue">
                <span >{{v.Class}}</span>
              </div>
            </div>
            <div class="rowtw bdot">
              <div class="wtitle">
                <span >Seats:</span>
              </div>
              <div class="wvalue">
                <span >{{v.Seats}}</span>
              </div>
            </div>
            <div class="rowtw bdot">
              <div class=" wtitle">
                <span >YOM:</span>
              </div>
              <div class=" wvalue">
                <span >{{v.YOM}}</span>
              </div>
            </div>
            <div class="rowtw bdot">
              <div class=" wtitle">
                <span >YOR:</span>
              </div>
              <div class=" wvalue">
                <span >{{v.YOR ? v.YOR : "-"}}</span>
              </div>
            </div>
            <div class="rowtw bdot" style="margin: 20px 0 0 0;">
              <div class=" wtitle" >
                <span style="font-weight: 600;">PRICE:</span>
              </div>
              <div class=" wvalue" >
                <span style="font-weight: 600;">{{v.Price}}</span>
              </div>
            </div>
          </div>
          <div v-if="i !== 'Images' && v.Images.length > 0" 
            class="row_images bdot" 
            >
            <div class="image" :style="{ backgroundImage: `url(${v.Images[0]})`}"
                  ></div>
            <div class="image" :style="{ backgroundImage: `url(${v.Images[1]})`}"
                  ></div>
          </div>
          
        </div>
        <hr style="border: 0.5px solid rgb(221, 221, 221);">
      </div>
    </div>
  </div>
    <!-- <div class="offers__item item" v-for="(v, i) in tableData" :key="i">
      <div v-if="i !== 'Images' && v.Images.length > 0" class="item__images images">
        <div class="images__aircraft" v-for="(val, ind) in v.Images" 
        :style="{ backgroundImage: `url(${val})`}"
             :key="ind"/>
      </div>
      <table class="item__sheet sheet">
        <thead class="sheet__header">
        <tr class="sheet__row row">
          <th class="row__item row__item_header"
              v-for="(val, ind) in [`Offer ${arr.indexOf(v) + 1}`, 'Aircraft class', 'YOM/Updates', 'Seats', 'Price ($)']"
              :key="ind">{{ val }}
          </th>
        </tr>
        </thead>
        <tbody class="sheet__body">
        <tr class="sheet__row row">
          <td v-for="(val, ind) in v" class="row__item"
              :key="ind">
            <span v-if="ind !== 'Images'">
                {{ val }}
            </span>
          </td>

        </tr>
        </tbody>
      </table>
    </div>  -->

    
   
</template>

<script>
export default {
  name: "VPdfOffers",
  data() {
    return{
        urlI : "http://clipart-library.com/image_gallery/614651.jpg",
    }  
},
  props: {
    tableData: {type: Array, required: true},
    arr: Array,
    priceMin: String,
    priceMax: String,
    length: Number,
    num: Number,
  },
  created() {
    // https://stackoverflow.com/questions/72112887/image-from-a-link-not-showing-in-html2pdf
    console.log("created", this.tableData);
    for (let i = 0; i < this.tableData.length; i++) {
      console.log("table row =", i)
       this.processImages(this.tableData[i].Images)

    }
    //this.urlI = "http://clipart-library.com/image_gallery/614651.jpg"
  },
  methods: {
    async processImages(imagesArray)  {
      if (!imagesArray || imagesArray.length === 0) return;
      for (let i = 0; i < imagesArray.length; i++) {
        console.log("i =", i)
        const imgURL = imagesArray[i];
        const image = await fetch(imgURL);
        const imageBlob = await image.blob();
        const blobUrl = URL.createObjectURL(imageBlob);
        imagesArray[i] = blobUrl;
      }
    }
  }
}
</script>

<style scoped lang="scss">
table,
thead,
tbody,
tfoot,
tr,
th,
// display: block;
td {
  
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border-collapse: inherit;
  border-spacing: 0;
  border: none inherit;
  vertical-align: inherit;
  text-align: left;
  font-weight: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}

// th, td {
//   display: inline;
// }
.bdot {
  // border-style: dotted;
  // border-width: 1px;
}
.bsol {
  // border-style: solid;
  // border-width: 1px;
}
.row {        
  //width: 730px;
  text-align: left;
  margin: 8px 10px;
 
}    
.rowao {
  //width: 730px;
  height: 116px;
  text-align: left;
  margin: 8px 10px;
  display: flex; 
  flex-direction: row;

}      
.rowi {
  width: 332px;   
  display: flex; 
  flex-direction: column;
  //min-height: 12px;
  padding: 0px;   
  margin: 0px 0 0 0px;
}
.rowtw {
  width: 332px; 
  margin: 0px 0 4px 0px;  
  display: flex; 
  flex-direction: row;
}
.wtitle {
  font-size: 10px;
  width: 99px; 
}
.wvalue {
  font-size: 10px;
  width: 233px; 
  padding: 0px; 
}
.row_images {
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  height: 116px;
  width: 378px;
  // border-style: dotted;
  // border-width: 1px;
}      
.image {
  height:116px; 
  width:186px; 
  background-size: cover;
}
.offers {
  &__header {
    @include tabHeadersPDF;
  }
  .head_offers {
    // font-weight: 700;
    // font-size: 14px;
  }
  .item {
    // .images {
    //   height: 116px;
    //   width: 378px;
      //margin-top: 8px;
      
      // &>*:not(:last-child) {
      //   margin-right: 8px;        
      // }

      // &__aircraft {
      //   flex: 0 0 32.5%;
      //   height: 150px;
      //   background-size: cover;
      //   background-position: center;
      //   background-repeat: no-repeat;
      // }
    // }

    .sheet {
      //width: 100%;
      // .row_center {
      //   display: flex;
      //   align-items: center;
      // }

        // &__item {
        //   text-align: left;
        //   flex: 0 0 19%;
        //   @include body-12-500;
        //   span {
        //     word-wrap: break-word;
        //     overflow: hidden;
        //     width: 100%;
        //   }
        //   &_header {
        //     @include body-12-600;
        //   }
        // }
      
    }
  }
}
</style>
