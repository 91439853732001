<template>
  <!-- <div>
  <table style="width:100%">
     <tr>
       <td>Alfreds Futterkiste</td>
       <td>Maria Anders</td>
       <td>Germany</td>
     </tr>
     <tr>
       <td>Centro comercial Moctezuma</td>
       <td>Francisco Chang</td>
       <td>Mexico</td>
     </tr>
   </table>
 </div> -->
 <div class="offers"> 
    <div class="offers__header">
     COMMENTS
   </div>
   <div>      
     <div v-for="(v, i) in tableData" :key="i">       
       <div v-if="v.Description" class="row" style="padding: 0px; margin: 10px 10px">         
          <div class="row" style="width:100%; font-size: 10px; padding: 0px; margin: 0px 0px;">
            <div class="bdot" style="width:271px; ">
              <span style="font-weight: 600; font-size: 10px;">OPTION {{i+1}}: </span>
              <span style="font-size: 10px;">{{v.Type}}</span>
            </div>
            <div class="bdot" style="width:439px; ">
              <span style="font-size: 10px;">{{v.Description}}</span>
            </div>
          </div>                             
       </div>       
       <hr v-if="v.Description" style="border: 0.5px solid rgb(221, 221, 221);">
     </div>
   </div>
 </div>
   
  
</template>

<script>
export default {
 name: "VPdfOffers",
 data() {return{
       
   }
},
 props: {
   tableData: {type: Array, required: true},
   arr: Array,
   priceMin: String,
   priceMax: String,
 }
}
</script>

<style scoped lang="scss">
table,
thead,
tbody,
tfoot,
tr,
th,
// display: block;
td {
 
 width: auto;
 height: auto;
 margin: 0;
 padding: 0;
 border-collapse: inherit;
 border-spacing: 0;
 border: none inherit;
 vertical-align: inherit;
 text-align: left;
 font-weight: inherit;
 -webkit-border-horizontal-spacing: 0;
 -webkit-border-vertical-spacing: 0;
}

// th, td {
//   display: inline;
// }
.bdot {
 // border-style: dotted;
 // border-width: 1px;
}
.bsol {
 border-style: solid;
 border-width: 1px;
}

.offers {
 &__header {
   @include tabHeadersPDF;
 }
 .head_offers {
   font-weight: 700;
   font-size: 14px;
 }
 
 .item {
   .images {
     display: flex;
     margin-top: 8px;
     &>*:not(:last-child) {
       margin-right: 8px;        
   }

     // &__aircraft {
     //   flex: 0 0 32.5%;
     //   height: 150px;
     //   background-size: cover;
     //   background-position: center;
     //   background-repeat: no-repeat;
     // }
   }

   .sheet {
     //width: 100%;

     .row {
       @include flexBetweenCenter;
       border-bottom: 1px solid $secondaryDarkColor;
       //width: calc(100% - 12px);
       text-align: left;

       &__item {
         text-align: left;
         flex: 0 0 19%;
         @include body-12-500;
         span {
           word-wrap: break-word;
           overflow: hidden;
           width: 100%;
         }
         &_header {
           @include body-12-600;
         }
       }
     }
   }
 }
}
</style>
