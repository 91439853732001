<template>
  <!-- <div>
  <table style="width:100%">
     <tr>
       <td>Alfreds Futterkiste</td>
       <td>Maria Anders</td>
       <td>Germany</td>
     </tr>
     <tr>
       <td>Centro comercial Moctezuma</td>
       <td>Francisco Chang</td>
       <td>Mexico</td>
     </tr>
   </table>
 </div> -->
 <div class="offers"> 
    <div class="offers__header">
     CONTACT
   </div>
   <div style="margin: 10px 10px">      
     <div v-for="(v, i) in info" :key="i">       
       <div class="row" style="font-size: 10px; padding: 0px; margin: 0px 0px">
         <div class="row" style="width:100%; padding: 0px; margin: 0 0 5px 0px">           
             <div class="bdot" style="width:132px;">
               <span style="font-weight: 600; font-size: 10px;">{{v.name}}:</span>
             </div>
             <div class="bdot" style="width:200px;">
               <span style="font-size: 10px;">{{v.descr}}</span>
             </div>  
         </div>                    
       </div>       
     </div>
     <div style="font-size: 10px; margin: 10px 0 0 0">
        Office No. 250, Block C 4.1, 55/18 Mangilik El ave.<br>
        Astana International Financial Centre AIFC, Astana, Republic of Kazakhstan
       </div>
   </div>
 </div>
   
  
</template>

<script>
export default {
 name: "VPdfOffers",
 data() {return{
    info:  [{
      name: "Email",
      descr: "ops@opensky.biz"
    },
    {
      name: "Phone Number",
      descr: "+44 7700 181801"
    },
    {
      name: "Additional Number",
      descr: "+7 706 424 9161"
    },
    {
      name: "Telegram",
      descr: "@manageropensky"
    },
    {
      name: "WhatsApp",
      descr: "+7 706 424 9161"
    },
    ]
   }
},
 props: {

 }
}
</script>

<style scoped lang="scss">
table,
thead,
tbody,
tfoot,
tr,
th,
// display: block;
td {
 
 width: auto;
 height: auto;
 margin: 0;
 padding: 0;
 border-collapse: inherit;
 border-spacing: 0;
 border: none inherit;
 vertical-align: inherit;
 text-align: left;
 font-weight: inherit;
 -webkit-border-horizontal-spacing: 0;
 -webkit-border-vertical-spacing: 0;
}

// th, td {
//   display: inline;
// }
.bdot {
 // border-style: dotted;
 // border-width: 1px;
}
.bsol {
 border-style: solid;
 border-width: 1px;
}

.offers {
 &__header {
   @include tabHeadersPDF;
 }
 .head_offers {
   font-weight: 700;
   font-size: 14px;
 }
 .item {
   .images {
     display: flex;
     margin-top: 8px;
     &>*:not(:last-child) {
       margin-right: 8px;        
   }

     // &__aircraft {
     //   flex: 0 0 32.5%;
     //   height: 150px;
     //   background-size: cover;
     //   background-position: center;
     //   background-repeat: no-repeat;
     // }
   }

   .sheet {
     //width: 100%;

     .row {
       padding: 6px;
       @include flexBetweenCenter;
       border-bottom: 1px solid $secondaryDarkColor;
       width: calc(100% - 12px);
       text-align: left;

       &__item {
         text-align: left;
         flex: 0 0 19%;
         @include body-10-500;
         span {
           word-wrap: break-word;
           overflow: hidden;
           width: 100%;
         }
         &_header {
           @include body-10-600;
         }
       }
     }
   }
 }
}
</style>
