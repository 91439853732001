<template>
  <div class="pdf__header" style="margin-bottom: 10px;">
    <div class="pdf__header_text " 
      style="display: flex; flex-direction: column; ">     
      <!-- <div style="padding: 0px; margin:0px 0;height: 20px;"> -->
        <span class="text__title" >
          Flight Quotation
        </span>
      <!-- </div> -->
      <!-- <div style="padding: 0px; margin: 0px; height: 10px;"> -->
        <span class="text__offernum" style="margin-top: 4px">
            OFFER №: {{ orderNum }}
        </span>      
      <!-- </div> -->
    </div>
    <img class="pdf__header_logo" src="../../../assets/logo_opensky_title_small2.svg" alt="opensky"/>
  </div>
</template>

<script>
export default {
  name: "VPdfHeader",
  props: {
    orderNum: {type: String, default: ''}
  }
}
</script>

<style scoped lang="scss">
.pdf__header {
  //@include flexBetweenCenter;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  height: 34px;
  
  .text {      
    &__title {      
      font-weight: 600;
      font-size: 16px;      
    }

    &__offernum {      
      font-weight: 500;
      font-size: 10px;
    }
  }

  &_logo {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 101px;
    height: 25.69px;
  }
}
</style>
