<template>
  <v-card>
    <user-modal
      :is-open="userModal"
      :current-title="currentTitle"
      :fields="fields"
      :type="userModalType"
      @close="userModal = false"
      @request="requestHandler"
    />
    <regions-modal
      @close="regionModal = false"
      @createRegion="regions.push($event)"
      :isOpen="regionModal"
      :template="template"
    />
    <v-tabs v-model="tab" centered @change="tabHandler">
      <v-tabs-slider color="#E53935"></v-tabs-slider>
      <v-tab
        v-for="el in tableTab"
        :key="el.title"
        :style="{ color: '#E53935' }"
      >
        {{ el.title }}
      </v-tab>
    </v-tabs>
    <regions-template-actions
      v-if="tableTab[tab]['path'] === 'countries'"
      @openModal="regionModal = true"
      @filter="regionFilter($event)"
      @addRegion="regionModal = true"
      @reset="resetRegionFilter"
      :regions="regions"
    />
    <v-data-table
      :items="table"
      :items-per-page="count"
      :headers="headers"
      :page.sync="page"
      class="mt-4"
      hide-default-footer
    >
      <template v-slot:body.prepend>
        <tr>
          <v-btn
            color="blue-grey lighten-4"
            class="my-3 mx-4"
            elevation="0"
            centered
            @click="openModal('add')"
            v-if="tableTab[tab]['path'] !== 'countries'"
            >Add row
          </v-btn>
          <td v-else></td>
          <td v-for="(val, key) in template" :key="'search' + key">
            <!-- v-model="query[key]" -->

            <v-text-field
              v-model="query[key]"
              class="subheading"
              @input="requestHandler"
              type="text"
              :placeholder="key"
            />
          </td>
        </tr>
      </template>
      <template
        v-if="tableTab[tab]['path'] === 'countries'"
        v-slot:item.actions="item"
      >
        <v-select
          v-if="regions.find(region => region.Id === item.item.RegionId)"
          style="width: 165px;"
          hide-details
          :items="regions"
          dense
          outlined
          item-value="RegionId"
          item-text="Name"
          :value="regions.find(region => region.Id === item.item.RegionId).Name"
          @change="countrySelectHandler($event, item.item)"
        />
      </template>
      <template v-else v-slot:item.actions="item">
        <div class="d-flex justify-space-around">
          <v-icon @click="openModal('edit', item)">mdi-pencil</v-icon>
          <v-icon @click="deleteRow(item)">mdi-delete</v-icon>
        </div>
      </template>

      <template v-slot:item.DateCreate="item">
        <td class="text-start">
          {{
            item.item.DateCreate
              | getFormattedDate({
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
              })
          }}
        </td>
      </template>

      <template v-slot:no-data> NO DATA </template>
    </v-data-table>
    <v-row align="center" class="px-4">
      <v-col cols="0" md="2">
        <span>Showing {{ count }} of {{ total }}</span>
      </v-col>
      <v-col cols="12" md="8">
        <v-pagination
          v-model="page"
          :length="Math.ceil(this.total / this.limit)"
          :total-visible="9"
          @input="paginationHandler"
          class="mt-4"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          class="pt-6"
          v-model="limit"
          @input="limitHandler"
          :items="[10, 15, 30, 50]"
          label="Items per page"
          dense
          outlined
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { getFormattedDate } from "../filters";

export default {
  name: "Admin",
  filters: {
    getFormattedDate
  },
  components: {
    RegionsTemplateActions: () =>
      import("@/components/Admin/RegionsTemplateActions"),
    RegionsModal: () => import("@/components/Admin/RegionsModal"),
    UserModal: () => import("@/components/Admin/UserModal")
  },
  data: () => {
    return {
      tab: 0,
      tableTab: [
        { title: "Users", path: "user" },
        { title: "Companies", path: "companies" },
        { title: "Regions", path: "countries" },
      ],
      table: [],
      template: {},
      page: 1,
      limit: 10,
      total: 0,
      count: 0,
      userModal: false,
      currentTitle: null,
      dateCreate: false,
      regions: [],
      regionModal: false,
      selectedRegionId: null,
      query: {},
      fields: {},
      userModalType: ""
    };
  },
  async mounted() {
    await this.requestHandler();
  },
  computed: {
    headers() {
      const headers = [
        {
          text: "Actions",
          width: "1%",
          value: "actions",
          sortable: false,
          class: "blue-grey title lighten-4"
        }
      ];
      for (const el in this.template) {
        headers.push({
          text: el,
          value: el,
          width: `${(el.length + 18) * 7}px`,
          sortable: true,
          class: "blue-grey title lighten-4"
        });
      }
      return headers;
    },
    queryString() {
      const arr = [];
      Object.entries(this.query).reduce((accumulator, currentValue) => {
        if (currentValue[1]) {
          return arr.push(`${currentValue[0]}=${currentValue[1]}`);
        }
      }, {});
      return arr.join("&");
    }
  },
  methods: {
    paginationHandler(val) {
      this.page = val;
      this.requestHandler();
    },
    limitHandler(val) {
      this.limit = val;
      this.requestHandler();
    },
    async countrySelectHandler(event, item) {
      const regionId = this.regions.find(el => el.Name === event);
      item.RegionId = regionId.Id;
      item.RegionDesc = event;
      try {
        await this.$api.request.editRow({
          url: "countries",
          data: item
        });
      } catch (e) {
        this.$toast.error("Error!");
      }
    },
    async tabHandler() {
      this.page = 1;
      this.query = {};
      await this.requestHandler();
    },
    async regionFilter(event) {
      this.selectedRegionId = event;
      await this.requestHandler();
    },
    async resetRegionFilter() {
      this.selectedRegionId = null;
      await this.requestHandler();
    },
    async requestHandler() {
      if (this.tableTab[this.tab]["path"] === "countries") {
        const regions = await this.$api.request.getTable(
          `regions?Page=${this.page}&Limit=${this.limit}`
        );
        this.regions = regions.data.Data;
      }

      const url = this.selectedRegionId
        ? `${this.tableTab[this.tab]["path"]}?Page=${this.page}&Limit=${
            this.limit
          }&RegionId=${this.selectedRegionId}&${this.queryString}`
        : `${this.tableTab[this.tab]["path"]}?Page=${this.page}&Limit=${
            this.limit
          }&${this.queryString}`;
      await this.$api.request.getTable(url).then(result => {
        this.table = result.data.Data;
        this.template = result.data.Template.BaseFields;
        if (result.data.Pagination) {
          const pagination = result.data.Pagination;
          this.page = pagination.Page;
          this.limit = pagination.Limit;
          this.count = pagination.Count;
          this.total = pagination.Total;
        }
      });
    },
    async openModal(type, data) {
      this.currentTitle = type;
      if (type === "add") {
        this.fields = this.template;
      } else {
        this.fields = data.item;
      }
      this.userModalType = type;
      this.fields.DateCreate = this.fields.DateCreate.substr(0, 10);
      this.userModal = true;
    },
    async deleteRow(payload) {
      await this.$api.request
        .deleteRow({ url: "user", data: JSON.stringify(payload.item) })
        .then(() => {
          this.requestHandler();
        })
        .catch(() => this.$toast.error("Error!"));
    }
  }
};
</script>
