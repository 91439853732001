var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('h2',[_vm._v(_vm._s(this.modalType))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":2}},[_c('v-text-field',{ref:"modalId",attrs:{"label":"Id","placeholder":"Id","dense":"","outlined":"","type":"text","autocomplete":"new-password"},model:{value:(_vm.modalData.Ids),callback:function ($$v) {_vm.$set(_vm.modalData, "Ids", $$v)},expression:"modalData.Ids"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('Autocomplete',{attrs:{"list":_vm.autocompleteOperator.Items,"selectionitem":_vm.autocompleteOperator.Menu,"selection":_vm.autocompleteOperator.Value,"url":"operators","urlname":"Autocomplete","label":"Operator","timeout":150},on:{"update:selectionitem":function($event){return _vm.$set(_vm.autocompleteOperator, "Menu", $event)},"updateauto":function($event){return _vm.saveAutocomplete(
                  'operatorMenu',
                  _vm.autocompleteOperator.Menu.OperatorName,
                  _vm.autocompleteOperator.Menu
                )}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Name)+" ")])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Autocomplete',{attrs:{"list":_vm.autocompleteClass.Items,"selectionitem":_vm.autocompleteClass.Menu,"selection":_vm.autocompleteClass.Value,"url":"aircrafts/classes","urlname":"Autocomplete","label":"Aircraft Class","timeout":150},on:{"update:selectionitem":function($event){return _vm.$set(_vm.autocompleteClass, "Menu", $event)},"updateauto":function($event){return _vm.saveAutocomplete(
                  'aircraftClassMenu',
                  _vm.autocompleteClass.Menu.Name,
                  _vm.autocompleteClass.Menu
                )}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Name)+" ")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Autocomplete',{attrs:{"list":_vm.autocompleteType.Items,"selectionitem":_vm.autocompleteType.Menu,"selection":_vm.autocompleteType.Value,"url":"aircrafts/types","urlname":"Name","label":"Aircraft Type","timeout":150},on:{"update:selectionitem":function($event){return _vm.$set(_vm.autocompleteType, "Menu", $event)},"updateauto":function($event){return _vm.saveAutocomplete(
                  'aircraftMenu',
                  _vm.autocompleteType.Menu.Name,
                  _vm.autocompleteType.Menu
                )}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Name)+" ")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{ref:"modalRegNum",attrs:{"label":"RegistrationNumber","placeholder":"Registration number","dense":"","outlined":"","type":"text"},model:{value:(_vm.modalData.Reg_num),callback:function ($$v) {_vm.$set(_vm.modalData, "Reg_num", $$v)},expression:"modalData.Reg_num"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{ref:"modalPax",attrs:{"label":"Pax","placeholder":"Pax","dense":"","outlined":"","type":"number"},model:{value:(_vm.modalData.PAX),callback:function ($$v) {_vm.$set(_vm.modalData, "PAX", $$v)},expression:"modalData.PAX"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{ref:"modalYear",attrs:{"label":"year of aircraft production","placeholder":"Year","dense":"","outlined":"","type":"number"},model:{value:(_vm.modalData.Year),callback:function ($$v) {_vm.$set(_vm.modalData, "Year", $$v)},expression:"modalData.Year"}})],1)],1),_c('v-row'),_c('v-row',[_c('aircraft-model-photo-gallery',{attrs:{"images":_vm.modalData.Images},on:{"delete":function($event){return _vm.imgHandler({ type: 'delete', data: $event })}}})],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.saveData}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }