<template>
  <div class="autocomplete">

    <v-text-field
          hide-details="auto"
          class="form-control"
          type="text"
          dense
          outlined
          :label="label"
          readonly
          :append-icon="open ? 'mdi-menu-up' : 'mdi-menu-down'"
          v-model="getCountriesCountInfo"          
          @click="onFieldClick"
          @click:append="onFieldClick"
        >
    </v-text-field>  
      
      
    <!-- <v-text-field
            append-icon="open ? chevron-up : chevron-down"
            :label="label"
            class="form-control"
            outlined
            readonly
            
      ></v-text-field> -->
    <div v-if="data && open" class="mt-2" >
      <v-text-field
          hide-details="auto"
          class="form-control"
          type="text"
          dense
          outlined
          clear-icon="mdi-close-circle-outline"
          clearable     
          placeholder="Filter"     
          v-model="search"
        ></v-text-field>
      <v-list class="" 
      style="width: 100%;"      
      >
        <!-- Список регионов -->
        <div
            v-for="(item, idx) in data"
            :key="idx"
            
          >
            <!-- <slot :item="item"> </slot> -->
          <div>
            <!-- <v-text-field 
              dense
              class="font-weight-bold" 
              :label="item.Name"
              :append-icon="item.Visible ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              readonly
              @click="item.Visible = !item.Visible" 
              ></v-text-field> -->
            <!-- <div class="font-weight-bold" >
              
              <v-icon @click="onRegionClick(item)"> {{item.Visible?'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
              @click="onRegionClick(item)" 
              :append-icon="item.Visible ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            </div> -->
            <div class="font-weight-bold" >
              {{ item.Name }}
            </div>
            <!-- Список стран -->
            <div  class="ml-6">
              <div v-for="(itemC, idxC) in item.Country"
                :key="idxC"
                >
                <div v-if="idxC == 0">
                  <v-checkbox v-if="!search"
                    dense 
                    label="All countries "  
                    v-model="item.Checked" 
                    @click="onAllCountryClick(item.Checked, item.Country)"
                  />
                </div>
                <div>
                  <v-checkbox v-if="itemC.Visible" dense :label="itemC.Name" v-model="itemC.Checked"
                    @click="onCountriesChecked(item, itemC, itemC.Checked)"
                  ></v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-list>
      
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      search: "",  
      data: {},
      open: false,
      countriesCount: 0,
      lastSearch:"",
      lastData: "",
      itemClick: false,
    };
  },
  props: {    
    label: {
      type: String,
      required: true
    },
    operatorId: {
      type: String,
      required: false
    },
    dataSelected: { 
      type: Array, 
      default: () => [] ,
      twoWay: true
    },
  },
  mounted() {
    // this.search = this.selection;
    this.search = "";
    this.data = {};
    console.log("GFSelect - mounted", "this.operatorId", this.operatorId)    
    this.getGFData();
  },
  watch: {
    search(newSearch) {   
      const tag = "GFSelect - searchFilter";   
      console.log(tag, newSearch);
      if (this.data == null) {
        console.log(tag, "data is null");
        return
      }
      if (this.data == undefined) {
        console.log(tag, "data is undefined");
        return
      }
      if (newSearch==""||newSearch==null) {
        this.data.forEach((el) => {
          el.Visible = true;
          if (el.Count == el.Country.filter((el)=> el.Checked).length) {
            el.Checked = true;                                      
          } else {
            el.Checked = false;
          }
          el.Country.forEach((elC) => {
              elC.Visible = true;
          });  
        });
      } else {
        this.data.forEach((el) => {
          el.Country.forEach((elC) => {            
            if (elC.Name.startsWith(this.search, 0)) {
              elC.Visible = true;
            } else {
              elC.Visible = false;
            }            
          });  
        });
      }    
      this.updateDataSelected();
    },
    operatorId(newOperatorId, oldOperatorId){ 
      const tag = "operatorId - watch operatorId";     
      console.log(tag, newOperatorId, oldOperatorId)
      this.getGFData();
    }
  },
  computed: {
    getCountriesCountInfo: function () {
      return this.countriesCount + " countries"
    }
  },
  methods: {
    async getGFData() {
      const tag = "GFSelect - getGFData";
       console.log(tag, "Старт ---")
       try {
        console.log(tag, "this.operatorId", this.operatorId)
        if (this.operatorId == "" || this.operatorId == undefined) {
          console.log(tag, "operatorId is ", this.operatorId)
          this.operatorId = 0
          
        }
        const result = await this.$api.request.get(
          "/operators/regions?OperatorId="+this.operatorId
        );
        //console.log(tag, result.data.Data);
        if (result.data.Data !== null) {                
            this.data = result.data.Data;
            this.data.forEach((el) => {
              el.Visible = true;
              if (el.Count == el.Country.filter((el)=> el.Checked).length) {
                el.Checked = true;                                      
              } else {
                el.Checked = false;
              }
              el.Country.forEach((elC) => {
                  elC.Visible = true;
              });  
            });
            this.setCountriesCount();
            this.updateDataSelected();
        } else {              
            console.log(tag, "getGFData Empty value");
        }
        } catch (e) {
            this.$toast.error(tag + "Error get of data: " + e.message);
        }  
    },
    setCountriesCount() {   
      const tag = "setCountriesCount";
      console.log(tag, "Старт ---")
      try {     
        this.countriesCount = 0; 
        this.data.forEach((el) => {
          if (el.Count > 0) {
            console.log(tag, "el =", el);
            el.Country.forEach((elC) => {
              if (elC.Checked == true) {                
                this.countriesCount += 1;    
              }
            })
          }
        })
      } catch (e) {
        console.log(tag, "error =", e);
      }
    },
    onAllCountryClick(Checked, Country) {
      // const tag = "onAllCountryClick";
      // console.log(tag, "Старт ---");
      // console.log(tag, "Country", Checked, Country);
      Country.forEach((el) => {        
          el.Checked = Checked;
      });
      this.setCountriesCount();
      this.updateDataSelected();
    },
    onCountriesChecked(region/*, country, countryChecked*/){ 
      // const tag = "onAllCountryClick";
      // console.log(tag, "Старт ---");
      // console.log(tag, "region", region, "country", country, "countryChecked", countryChecked);
      region.Checked = false;
      this.setCountriesCount();
      this.updateDataSelected();
      
    },
    onFieldClick() {
      // const tag = "onFieldClick";
      // console.log(tag, "Старт ---");
      // console.log(tag, "open", this.open)
      this.open = !this.open;
    },
    onFieldClick2() {
      // const tag = "onFieldClick2";
      // console.log(tag, "Старт ---");
      this.open = false;
    },
    onRegionClick(/*item*/) {
      // const tag = "onRegionClick";
      // console.log(tag, "Старт ---");
      // item.Visible = !item.Visible;
      // console.log(tag, "item.Visible", item.Visible, item.Name);
      //return item.Visible;
      this.data.forEach((el) => {
         el.Visible = true;
      });
      // console.log(tag, "data", this.data);
    },
    updateDataSelected() {
      // const tag = "updateDataSelected";
      // console.log(tag, "Старт ---");
      
      this.$emit('updateCountries', this.data);
    },
    checkFocus() {
      // const tag = "checkFocus";
      // console.log(tag, "Старт ---");
      // setTimeout(() => {
      //   this.lastSearch = this.search;
      //   this.lastData = this.data;
      // }, 10);
      
      // setTimeout(() => {
      //   console.log(tag, "itemClick", this.itemClick)
      //   if (this.search === this.lastSearch) {
      //     console.log(tag, "this.search === lastSearch");
      //   } else {
      //     console.log(tag, "this.search <> lastSearch");
      //   }
      //   if (this.lastData.join() === this.data.join()) {
      //     console.log(tag, "lastData.join() === data.join()");
      //   } else {
      //     console.log(tag, "lastData.join() <> data.join()");
      //   }
      //   if (this.search === this.lastSearch && this.lastData.join() === this.data.join()) {
      //     //this.open = false;
      //   }
      //   //this.itemClick = false;
      // }, 2000);
    },
  },
};
</script>

<style scoped lang="css">
.autocomplete {
  position: relative;
}

.v-list {
  position: absolute;
  background: #fff;
  width: inherit;
  max-width: inherit;
  height: inherit;
  z-index: 999;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px !important;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%);
}

.autocomplete-list::-webkit-scrollbar {
  display: none;
}

.focus {
  background: rgba(0, 0, 0, 0.2);
  color: #1976d2 !important;
}
</style>
