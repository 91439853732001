<template>
  <div>
    <div class="order-page">
      <order-modal @request="requestHandler" :page="page" :limit="limit" @close="modalAddOrder = false"
                   :flyTypeItems="flyTypeItems" :modal-add-order="modalAddOrder"/>
    </div>
    <v-card elevation="0" class="pa-4 pt-0">
      <v-tabs
          :value="currentTab"
          @change="currentTabHandler"
          centered
          class="mb-0"
      >
        <v-tabs-slider/>
        <v-tab v-for="el in ordersTab" :key="el">
          {{ el }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
          v-model="currentTab"
          @change="currentTabHandler"
          :value="currentTab"
          class="order-list"
      >
        <v-tab-item>
          <v-container>
            <div class="filter-block">
              <div class="filter-block__btn mt-2">
                <div class="pagination-filter__item pagination-list">
                  <v-select
                      v-model="limit"
                      @input="limitHandler"
                      :items="[10, 15, 30, 50]"
                      label="Items per page"
                      class="pt-6"
                  />
                </div>
                <div class="filter-block__btn-list">
                  <!--                  <button class="btn-filter mr-16" @click="modalAddOrder = true">-->
                  <!--                    Add order-->
                  <!--                  </button>-->
                  <button class="btn-filter mr-16" @click="clickfilterOpen">
                    Filter
                  </button>
                  <button class="btn-filter" @click="clickShowOrders">
                    {{ showTextOrders ? "Show less" : "Show more" }}
                  </button>
                </div>
                <div class="pagination-filter__item pagination-count">
                  <!-- <v-pagination
                    v-model="page"
                    :length="Math.ceil(total / limit)"
                    :total-visible="7"
                    @input="requestHandler"
                  /> -->
                  <Pagination
                      :total="total"
                      :limit="limit"
                      :page.sync="page"
                      @handler="requestHandler"
                  />
                </div>
              </div>
              <order-filter v-if="filterOpen && orderStatusItems && orderTypeItems"
                            :order-status-items="orderStatusItems"
                            :order-type-items="orderTypeItems"
                            :fly-type-items="flyTypeItems"
                            @request="requestHandler"
                            @close="filterOpen = false"
                            @setPage="page = $event"/>
            </div>
            <v-col
                v-for="(order, i) in allOrder"
                class="mx-auto order-list__item"
                :key="i"
                cols="12"
                :items-per-page="count"
                :page.sync="page"
            >
              <OrderItem :order="order"/>
              <!-- @openOrderOne="funOpenOrderOne($event)" -->
            </v-col>
            <div class="d-flex justify-center mt-10" v-if="this.notDataOrders">
              No data available
            </div>
            <div
                class="
                pagination-filter
                d-flex
                justify-space-between
                align-center
                mr-16
              "
            ></div>
          </v-container>
        </v-tab-item>
        <v-tab-item v-for="(order, i) in ordersTab.slice(1)" :key="i">
          <OrderInfo @updateOrders="fetchOrders" :item="orderHandler(order)"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OrderItem from "../components/Orders/OrderItem";
import OrderInfo from "../components/Orders/OrderInfo/OrderInfo";
import {getFormattedDate} from "../filters";
import Pagination from "../components/Pagination";
import OrderModal from "@/components/OrderModal";
import OrderFilter from "@/components/Orders/OrderFilter";

export default {
  name: "Orders",
  data: () => {
    return {
      showTextOrders: false,
      orders: [],
      orderStatusItems: null,
      orderTypeItems: null,
      template: {},
      tab: 0,
      StartDate: null,
      StartDateFormatted: null,
      StartTime: {
        type: String,
        default: "T00:00:00Z",
      },
      EndDate: null,
      EndDateFormatted: null,
      EndTime: {
        type: String,
        default: "T23:59:59Z",
      },
      query: {
        orderNumber: "",
        status: [0],
        orderType: [0],
        orderEmail: "",
        orderSurname: "",
        orderName: "",
      },
      notDataOrders: false,
      pagination: {
        orderBy: "",
        sort: "",
      },
      filterOpen: false,
      modalAddOrder: false,
      page: 1,
      limit: 10,
      total: 0,
      count: 0,
      currentAutoItem: null,
      flyTypeItems: null,
      lastCall: null,

      paxRules: [
        v => (v && v > 0) || 'Pax is required',
      ],

      departureAirportRules: [
        v => (v && v.length > 0) || 'Departure airport is required',
      ],
      stringRules: [
        v => (v && v.length > 0) || 'Arrival airport is required',
      ],

    };
  },
  components: {
    OrderItem,
    OrderInfo,
    Pagination,
    OrderModal,
    OrderFilter
  },
  filters: {
    getFormattedDate,
  },
  async mounted() {
    await this.$api.request.getTable(`orders/flyType`).then((resp) => {
      this.flyTypeItems = [{Id: 0, Name: "All"}, ...resp.data.Data];
    });

    await this.$api.request.getTable(`/orders/status`).then((resp) => {
      this.orderStatusItems = [{Id: 0, Name: "All"}, ...resp.data.Data];
    });

    await this.$api.request.getTable(`/orders/type`).then((resp) => {
      this.orderTypeItems = [{Id: 0, Name: "All"}, ...resp.data.Data];
    });

    this.requestHandler();
  },
  computed: {
    allOrder() {
      return this.$store.state.orders.allOrders;
    },
    ordersTab() {
      return this.$store.state.orders.tabs;
    },
    currentTab() {
      return this.$store.state.orders.currentTab;
    },
    queryProps: {
      get: function () {
        var queryEmail = ``;
        var querySurname = ``;
        var queryName = ``;
        var queryDate = ``;
        var queryStatus = this.query.status.filter((item) => item !== 0);
        var queryType = this.query.orderType.filter((item) => item !== 0);
        if (
            this.StartDateFormatted &&
            this.EndDateFormatted &&
            this.StartTime.default &&
            this.EndTime.default != null
        ) {
          queryDate = `&StartDateTimeOrder=${this.parseDate(
              this.StartDateFormatted
          )}${this.StartTime.default}&EndDateTimeOrder=${this.parseDate(
              this.EndDateFormatted
          )}${this.EndTime.default}`;
        }
        if (this.query.orderEmail) {
          queryEmail = `&CEmail=${this.query.orderEmail}`;
        }
        if (this.query.orderSurname) {
          querySurname = `&CLastName=${this.query.orderSurname}`;
        }
        if (this.query.orderName) {
          queryName = `&CFirstName=${this.query.orderName}`;
        }
        var resultFilter =
            `OrderNum=${this.query.orderNumber}` +
            queryEmail +
            querySurname +
            queryName +
            queryDate +
            `${
                queryStatus[0] !== undefined ? `&OrderStatusId=${queryStatus}` : ""
            }` +
            `${queryType[0] !== undefined ? `&OrderTypeId=${queryType}` : ""}`;
        return resultFilter;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  watch: {
    StartDate(val) {
      this.StartDateFormatted = this.formatDate(val);
    },
    EndDate(val) {
      this.EndDateFormatted = this.formatDate(val);
    },
  },
  methods: {
    ...mapActions({
      fetchFilterOrders: "orders/fetchFilterOrders",
      fetchOrders: "orders/fetchOrders",
      setTab: "orders/tabHandler",
      setCurrentTab: "orders/currentTabHandler",
    }),
    ...mapGetters({
      getPath: "table/getPath",
    }),
    debounce(f, t) {
      const vm = this;
      return function (args) {
        let previousCall = vm.lastCall;
        vm.lastCall = Date.now();
        if (previousCall && vm.lastCall - previousCall <= t) {
          clearTimeout(vm.lastCallTimer);
        }
        vm.lastCallTimer = setTimeout(() => f(args), t);
      };
    },
    paginationHandler(val) {
      this.page = val;
      this.requestHandler();
    },
    async limitHandler() {
      this.page = 1;
      this.requestHandler();
    },
    formatDate(StartDate) {
      if (!StartDate) return null;
      const [year, month, day] = StartDate.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    clickfilterOpen() {
      var weekDays = new Date();
      weekDays.setDate(weekDays.getDate() - 7);
      this.StartDateFormatted = weekDays
          .toLocaleDateString()
          .substr(0, 10)
          .split(".")
          .join("-");
      this.EndDateFormatted = new Date()
          .toLocaleDateString()
          .substr(0, 10)
          .split(".")
          .join("-");
      this.filterOpen = !this.filterOpen;
    },
    clickShowOrders() {
      let currentOrdersAll = document.querySelectorAll("[data-num]");
      this.showTextOrders = !this.showTextOrders;
      currentOrdersAll.forEach((item) => {
        if (this.showTextOrders == false) {
          item.classList.remove("active__order");
        } else {
          item.classList.add("active__order");
        }
      });
    },
    async requestHandler() {
      try {
        if (
            localStorage.getItem("filter") !== "none" &&
            localStorage.getItem("filter") !== null
        ) {
          await this.fetchFilterOrders(
              `${localStorage.getItem("filter")}&Page=${this.page}&Limit=${
                  this.limit
              }`
          );
        } else {
          await this.fetchFilterOrders(
              `${this.queryProps}&Page=${this.page}&Limit=${this.limit}`
          );
        }

        this.template = this.$store.state.orders.BaseFields;
        if (this.$store.state.orders.pagination) {
          const pagination = this.$store.state.orders.pagination;
          this.page = pagination.Page;
          this.limit = pagination.Limit;
          this.count = pagination.Count;
          this.total = pagination.Total;
        }

        this.notDataOrders = false;
      } catch (e) {
        this.$toast.warning("Данные не пришли");
        this.notDataOrders = true;
        this.$store.state.orders.allOrders = [];
      }
    },
    currentTabHandler(tab) {
      this.setCurrentTab(tab);
    },
    orderHandler(data) {
      return this.allOrder.find((el) => el.OrderNum === data);
    },
    queryHandler() {
      const arr = [];
      for (let i in this.query) {
        if (this.query[i] !== "") {
          arr.push(i + "=" + this.query[i]);
        } else {
          delete arr[i];
        }
      }
      return arr.join("&");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-order {
  .col {
    max-height: 65px;
  }

  &__item {
    position: relative;

    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 30px;
      right: 10px;
      cursor: pointer;
      transition: all;

      &:hover {
        opacity: 0.8;
      }

      span {
        display: block;
        width: 20px;
        height: 1px;
        background: #e53935;

        &:first-child {
          transform: rotate(-135deg);
        }

        &:last-child {
          transform: rotate(135deg);
          margin-top: -1px;
        }
      }
    }
  }
}

.filter-block__btn {
  position: relative;
  align-items: center;

  .pagination-list {
    margin-left: 12px;
  }

  .pagination-count {
    margin-left: auto;
    margin-right: 12px;
  }
}

.filter-block__btn-list {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
