<template>
  <Loader v-if="loading" />
  <v-container v-else-if="operator" fluid>
    <AircraftModal
      v-if="modal"
      :data="custModal"
      :saveModal="saveModal"
      :cancelModal="cancelModal"
      :operatorId="currentTab"
    />
    <OperatorModal
      v-if="operatorModal"
      :data="custOperatorModal"
      :saveModal="saveOperator"
      :cancelModal="cancelModal"
    />
    <v-row class="offer__client">
      <v-col col="8">
        <v-row class="offer__client-info">
          <v-col class="offer__client-item" cols="4">
            <p><strong> Name:</strong> {{ operator.Name }}</p>
          </v-col>
          <v-col class="offer__client-item" cols="4">
            <p><strong> Country:</strong> {{ operator.CountryDesc }}</p>
          </v-col>
          <v-col class="offer__client-item" cols="4">
            <p><strong> City:</strong> {{ operator.City }}</p>
          </v-col>
          <v-col class="offer__client-item" cols="4">
            <p>
              <strong>Website:</strong
              ><a target="_blank" :href="'https://' + operator.Website">
                {{ operator.Website }}</a
              >
            </p>
          </v-col>
          <v-col class="offer__client-item" cols="4">
            <p><strong> Email:</strong> {{ operator.Email }}</p>
          </v-col>
          <v-col class="offer__client-item" cols="4">
            <p><strong> Fax:</strong> {{ operator.Fax }}</p>
          </v-col>
          <v-col class="offer__client-item" cols="8">
            <p><strong> Phone:</strong> {{ operator.Phone }}</p>
          </v-col>
          <v-col class="offer__client-item" cols="2">
            <div >
              <v-tooltip bottom color="grey lighten-3">
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                  ><span><strong> Fly regions: </strong> {{ GFInfo }}</span>                  
                </p>
                </template>
                <div v-for="(item, idx) in GFInfoTooltip" :key="idx">
                  <p style="color:black"><strong>{{ item.Region }}:</strong>{{ item.Info }}</p>                  
                </div>
              </v-tooltip>
            </div>
          </v-col>
          <div
            class="offer__client-edit"
            @click="modalOperatorHandler({ type: 'edit', data: operator })"
          >
            <v-icon>mdi-pencil</v-icon>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-tabs v-model="currentTabOperator" class="mb-0" style="margin-top: 12px">
      <v-tabs-slider />
      <v-tab v-for="(el, i) in tabItems" :key="i">
        {{ el }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTabOperator">
      <v-tab-item v-for="(el, i) in tabItems" :key="i">
        <div class="pa-0">
          <v-data-table
            v-if="aircrafts"
            :items="aircrafts"
            :headers="offerHeaders"
            :page.sync="page"
            :items-per-page="count"
            :options.sync="options"
            class="offer-table"
            hide-default-footer
          >
            <template v-slot:item.Images="item">
              <v-img
                contain
                max-width="40px"
                max-height="40px"
                :value="item"
                :src="item.item.Images[0]"
              />
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-space-around">
                <v-icon @click="modalHandler({ type: 'edit', data: item })"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="rowHandler({ data: item })">mdi-delete</v-icon>
              </div>
            </template>
            <template v-slot:body.prepend>
              <tr>
                <td v-for="(val, key) in template" :key="'search' + key">
                  <v-text-field
                    class="subheading"
                    v-model="query[key]"
                    @input="aircraftsHandler"
                    type="text"
                    :placeholder="key"
                  />
                </td>
                <v-btn
                  color="blue-grey lighten-4"
                  class="my-3 mx-4"
                  elevation="0"
                  centered
                  @click="modalHandler({ type: 'create', data: template })"
                  >Add row
                </v-btn>
              </tr>
            </template>
          </v-data-table>
          <div v-else>
            <h2 class="text-center mt-4">No data available</h2>
          </div>
          <v-row align="center" class="px-4 d-flex align-center">
            <v-col cols="auto" md="auto" class="showing d-flex align-center">
              <span>Showing {{ count }} of {{ total }}</span>
              <v-select v-model="limit" :items="[10, 15, 30]" :height="30" />
            </v-col>
            <v-col cols="12" md="8">
              <v-pagination
                v-model="page"
                :length="Math.ceil(total / limit)"
                :total-visible="9"
                @input="paginationHandler"
              />
            </v-col>

            <v-col cols="12" md="1" class="d-flex ml-auto justify-end">
              <v-btn
                color="red darken-1"
                text
                @click="buttonHandler(currentTab)"
                >Close
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AircraftModal from "@/components/ModalWindows/AircraftModal";
import OperatorModal from "@/components/ModalWindows/OperatorModal";
import Loader from "@/components/Loader";
import { mapActions } from "vuex";
export default {
  components: {
    Loader,
    AircraftModal,
    OperatorModal
  },
  data() {
    return {
      options: {},
      tabItems: ["Aircrafts"],
      modal: false,
      operatorModal: false,
      template: null,
      custModal: {
        data: null,
        template: null
      },
      custOperatorModal: {
        data: null,
        template: null
      },
      loading: false,
      operator: null,
      aircrafts: null,
      page: 1,
      limit: 10,
      count: 0,
      total: 0,
      query: {},
      currentTabOperator: 0,
      dataGF: [],
      GFInfo: "",
      GFInfoTooltip: [
      ],
    };
  },
  watch: {
    limit() {
      this.page = 1;
      this.aircraftsHandler();
    },
    options: {
      async handler () {
        console.log("options", "sortBy", this.options.sortBy[0], "sortDesc", this.options.sortDesc[0])
        await this.aircraftsHandler();        
      },
      deep: true,
    },

  },
  computed: {
    allOrder() {
      return this.$store.state.operators.tabs;
    },
    currentTab() {
      return this.allOrder[this.$store.state.operators.currentTab].id;
    },
    offerHeaders() {
      const headers = [];
      for (const el in this.template) {
        headers.push({
          text: el,
          value: el,
          width: `${(el.length + 18) * 7}px`,
          sortable: true,
          class: "blue-grey title lighten-4"
        });
      }
      headers.push({
        text: "Actions",
        width: "1%",
        value: "actions",
        sortable: false,
        class: "blue-grey title lighten-4"
      });
      return headers;
    }
  },
  async mounted() {
    await this.requestHandler();
    await this.aircraftsHandler();
    this.getGFData();
  },
  methods: {
    ...mapActions({ deleteTab: "operators/deleteTab" }),
    async getGFData() {
       const tag = "GFSelect - getGFData";
       console.log(tag, "Старт ---")
       try {        
        console.log(tag, "this.operatorId", this.operator.Id)
        if (this.operator.Id == "" || this.operator.Id == undefined) {
          console.log(tag, "opratorId is undefined")
          return
        }
        const result = await this.$api.request.get(
          "/operators/regions?OperatorId="+this.operator.Id
        );
        console.log(tag, result.data.Data);
        let cn = 0;
        let cnC = 0;
        this.GFInfoTooltip = []
        if (result.data.Data !== null) {                
            this.dataGF = result.data.Data;
            this.dataGF.forEach((el) => { // регионы
              cnC = 0;
              el.Country.forEach((elC) => { // страны
                  if (elC.Checked) {
                    cn++;
                    cnC++;
                  }
              });  
              if (cnC > 0) {
                if (cnC == el.Count) {
                  this.GFInfoTooltip.push({
                    Region: el.Name,
                    Info: ` All countries`
                  });
                } else {
                  this.GFInfoTooltip.push({
                    Region: el.Name,
                    Info: ` ${cnC} countries`
                  });
                }
                console.log(tag, "cnC", cnC);
              }
            });
            this.GFInfo = `${cn} countries`
        } else {              
            console.log(tag, "Empty value");
        }
        } catch (e) {
            this.$toast.error(tag, "Error get of data Geography of Flight: " + e.message);
        }  
    },
    cancelModal() {
      this.modal = false;
      this.operatorModal = false;
    },
    async rowHandler({ type, data }) {
      console.log("rowHandler", type, data)
      try {
        if (type === "create") {
          await this.$api.request.createRow({ url: "aircrafts", data });
        } else if (type === "edit") {
          await this.$api.request.editRow({ url: "aircrafts", data });
        } else {
          await this.$api.request.deleteRow({
            url: "aircrafts",
            data: { Id: data.Id }
          });
        }
        this.modal = false;
        await this.aircraftsHandler();
        this.$toast.success("Successfully operation");
      } catch (e) {
        //this.$toast.error("Error");
      }
    },
    modalHandler({ type, data }) {
      console.log("modalHandler", type, data)
      this.modal = true;
      this.custModal = Object.assign(
        {},
        JSON.parse(JSON.stringify({ type, data }))
      );
    },
    async saveModal(type, data) {
      await this.rowHandler({ type, data });
      
    },
    async operatorHandler({ type, data }) {
      try {
        console.log("operatorHandler", type, data)
        if (type === "create") {
          await this.$api.request.createRow({ url: "operators", data });
        } else if (type === "edit") {
          await this.$api.request.editRow({ url: "operators", data });
        } else {
          await this.$api.request.deleteRow({
            url: "operators",
            data: { Id: data.Id }
          });
        }
        this.operatorModal = false;
        await this.requestHandler();
        this.$toast.success("Successfully operation");
      } catch (e) {
        //this.$toast.error("Error");
      }
    },
    modalOperatorHandler({ type, data }) {
      console.log("modalOperatorHandler", type, data)
      this.operatorModal = true;
      this.custOperatorModal = Object.assign(
        {},
        JSON.parse(JSON.stringify({ type, data }))
      );
    },
    async saveOperator(type, data) {
      await this.operatorHandler({ type, data });
      this.getGFData();
    },
    async requestHandler() {
      this.loading = true;
      await this.$api.request
        .getTable(`/operators?Id=${this.currentTab}`)
        .then(resp => {
          this.operator = resp.data.Data[0];
        });
      this.loading = false;
    },
    async aircraftsHandler() {
      const query = await this.queryHandler();
      await this.$api.request
        .getTable(
          `/aircrafts?${query}&OperatorId=${this.currentTab}&Page=${this.page}&Limit=${this.limit}`
        )
        .then(resp => {
          this.template = resp.data.Template.BaseFields;
          delete this.template.Operator;
          delete this.template.OperatorId;
          this.aircrafts = resp.data.Data;
          this.page = resp.data.Pagination.Page;
          this.limit = resp.data.Pagination.Limit;
          this.total = resp.data.Pagination.Total;
          this.count = resp.data.Pagination.Count;
        });
    },
    queryHandler() {
      const arr = [];
      for (let i in this.query) {
        if (this.query[i] !== "") {
          arr.push(i + "=" + this.query[i]);
        } else {
          delete arr[i];
        }
      }
      console.log("queryHandler - options", this.options)
      if ('sortBy' in this.options) {
      switch(this.options.sortBy[0]){ // Aircraft
        case "Ids": 
          arr.push("OrderBy=id");
          break;
        case "Country": 
          arr.push("OrderBy=country");
          break;
        case "Operator": 
          arr.push("OrderBy=operator_name");
          break;  
        case "OperatorId": 
          arr.push("OrderBy=operator_id");
          break;  
        case "Homebase": 
          arr.push("OrderBy=homebase");
          break;  
        case "Class": 
          arr.push("OrderBy=aircraft_class");
          break;                  
        case "Type": 
          arr.push("OrderBy=aircraft_type");
          break;    
        case "Reg_num": 
          arr.push("OrderBy=registration_number");
          break;    
        case "PAX": 
          arr.push("OrderBy=max_passengers");
          break;         
        case "Year": 
          arr.push("OrderBy=year_of_production");
          break; 
        case "PriceFact": 
          arr.push("OrderBy=price_fact");
          break;     
        case "PriceWeightedAverage": 
          arr.push("OrderBy=price_weighted_average");
          break;                 
      } 
      
        console.log("queryHandler - options.sortDesc[0]",this.options.sortDesc[0])
        switch (this.options.sortDesc[0]){
          case true: arr.push("OrderSort=desc");
          break;
          case false: arr.push("OrderSort=asc");
          break;
        }
      }
      return arr.join("&");
    },
    paginationHandler(val) {
      this.page = val;
      this.aircraftsHandler();
    },
    buttonHandler(tab) {
      this.deleteTab(tab);
    }
  }
};
</script>
