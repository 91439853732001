export default {
    data() {
        return {
            filesExterior: [],
            filesInterior: [],
            filesDiagram: [],
        }
    },
    methods: {
        fileToBase64(filename, filepath){
            return new Promise(resolve => {
                let file = new File([filename], filepath);
                let reader = new FileReader();
                // Read file content on file loaded event
                reader.onload = function(event) {
                    resolve(event.target.result);
                };
                // Convert data to base64
                reader.readAsDataURL(file);
            });
        },
        addFile(file, nameFile) {
            switch (nameFile) {
                case 'exterior': {
                    this.filesExterior = file
                    break
                }
                case 'interior': {
                    this.filesInterior = file
                    break
                }
                case 'diagram': {
                    this.filesDiagram = file
                    break
                }
                default: {
                    break
                }
            }
        },
        async deleteFile(nameFile) {
            switch (nameFile) {
                case 'exterior': {
                    if (this.getPhotosExterior.length > 0 || this.filesExterior.length > 0) {
                        if (this.getPhotosExterior.length > 0) {
                            await this.deleteAircraftImages(nameFile)
                        }
                    }
                    this.filesExterior = []
                    break
                }
                case 'interior': {
                    if (this.getPhotosInterior.length > 0 || this.filesInterior.length > 0) {
                        if (this.getPhotosInterior.length > 0) {
                           await this.deleteAircraftImages(nameFile)
                        }
                    }
                    this.filesInterior = []
                    break
                }
                case 'diagram': {
                    if (this.getPhotosDiagram.length > 0 || this.filesDiagram > 0) {
                        if (this.getPhotosDiagram.length > 0) {
                          await  this.deleteAircraftImages(nameFile)
                        }
                    }
                    this.filesDiagram = []
                    break
                }
                default: {
                    break
                }
            }
        },
    }
}
