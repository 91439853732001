import api from '@/api/index.js';

export default {
    namespaced: true,
    state: {
        tabs: ['Orders'],
        currentTab: 0,
        currentOrderTab: null,
        emailModal: false,
        allOrders: null,
        currentOrder: null,
        orderModal: false,
        reqCounter: 0,
        pagination: null,
        baseFields: null,
        photosExterior: [],
        confirmedLength: 0,
        allFlyToPdf: null,
        requestLoading: false,
        photosInterior: [],
        currentAutoItem: null,
        listCountries: [],
        listRegions: [],
        listOperators: [],
        photosDiagram: [],

    },
    getters: {
        getCurrentOrderTab: s => s.currentOrderTab,
        getCurrentOrder: s => s.currentOrder,
        getRequestLoading: s => s.requestLoading,
        getReqCounter: s => s.reqCounter,
        getAllFlyToPdf: s => s.allFlyToPdf,
        getOrderModal: s => s.orderModal,
        getListOperators: s => s.listOperators,
        getListRegions: s => s.listRegions,
        getListCountries: s => s.listCountries,
        getCurrentAutoItem: s => s.currentAutoItem,
        getPath: s => s.path,
        getAllTabs: s => s.tabs,
        getAllOrders: s => s.allOrders,
        getCurrentTab: s => s.currentTab,
        getConfirmedLength: s => s.confirmedLength,
        getEmailModal: s => s.emailModal,
        getPhotosDiagram: s => s.photosDiagram,
        getPhotosExterior: s => s.photosExterior,
        getPhotosInterior: s => s.photosInterior,
    },
    mutations: {
        SET_ORDERS: (s, v) => (s.allOrders = v),
        SET_REQUEST_LOADING: (s, v) => (s.requestLoading = v),
        SET_REQ_COUNTER: (s, v) => (s.reqCounter = v),
        SET_LIST_OPERATORS: (s, v) => (s.listOperators = v),
        SET_LIST_REGIONS: (s, v) => (s.listRegions = v),
        SET_ALL_FLY_TO_PDF: (s, v) => (s.allFlyToPdf = v),
        SET_CURRENT_AUTO_ITEM: (s, v) => (s.currentAutoItem = v),
        SET_CONFIRMED_LENGTH: (s, v) => (s.confirmedLength = v),
        SET_ORDER_MODAL: (s, v) => (s.orderModal = v),
        SET_EMAIL_MODAL: (s, v) => (s.emailModal = v),
        SET_PAGINATION: (s, v) => (s.pagination = v),
        SET_TEMPLATE: (s, v) => (s.baseFields = v),
        SET_CURRENT_ORDER: (s, v) => (s.currentOrder = v),
        SET_TAB: (s, v) => (s.tabs.push(v)),
        SET_CURRENT_TAB: (s, v) => (s.currentTab = v),
        DELETE_TAB: (s, v) => (s.tabs = s.tabs.filter((el) => el !== v)),
        SET_LIST_COUNTRIES: (s, v) => (s.listCountries = v),
        SET_PHOTOS_EXTERIOR: (s, v) => {
            if (v) {
                if (!s.photosExterior.find(el => el.Id === v.Id)) {
                    s.photosExterior.push(v)
                }
            } else {
                s.photosExterior = []
            }
        },
        SET_PHOTOS_INTERIOR: (s, v) => {
            if (v) {
                if (!s.photosInterior.find(el => el.Id === v.Id)) {
                    s.photosInterior.push(v)
                }
            } else {
                s.photosInterior = []
            }
        },
        SET_PHOTOS_DIAGRAM: (s, v) => {
            if (v) {
                if (!s.photosDiagram.find(el => el.Id === v.Id)) {
                    s.photosDiagram.push(v)
                }
            } else {
                s.photosDiagram = []
            }
        },
    },
    actions: {
        // async sendOfferToClients({state}) {
        //     const res = await api.request.createRow({
        //         url: `orders/sendOffersToClients?OrderNum=${state.currentOrder.Id}`,
        //         data: {}
        //     })
        //     return res.status
        // },
        async saveOrder({commit}, modalData) {
            try {
                const res = await api.request.editRow({url: `orders/flyInfo`, data: JSON.stringify(modalData)})
                if (res.status === 200) {
                    commit("SET_ORDER_MODAL", false)
                    commit("SET_CURRENT_ORDER", modalData)
                }
            } catch (e) {
                throw new Error(e)
            }
        },
        async changeOrderStatus({state}) {
            const res = await api.request.editRow({url: `orders`, data: JSON.stringify(state.currentOrder)})
            return res.status === 200
        },
        async requestOfferFromOperator({commit}, data) {
            try {
                commit("SET_REQUEST_LOADING", true)
                const res = await api.request.getTable(
                    `operators?Name=${
                        data.requestOp.operator ? data.requestOp.operator : ""
                    }&Country=${
                        data.requestOp.country ? data.requestOp.country : ""
                    }&Region=${
                        data.requestOp.region ? data.requestOp.region : ""
                    }&PAXFrom=${
                        data.requestOp.PAXFrom ? data.requestOp.PAXFrom : "0"
                    }&PAXTo=${data.requestOp.PAXTo ? data.requestOp.PAXTo : ""}&Class=${
                        data.requestOp.AircraftClass ? data.requestOp.AircraftClass : ""
                    }&limit=20`
                );
                commit("SET_REQ_COUNTER", res.data.Pagination.Total)
                commit("SET_LIST_OPERATORS", res.data.Data)
            } catch (e) {
                throw new Error(e)
            } finally {
                commit("SET_REQUEST_LOADING", false)
            }
        },
        async generatePdfOffers({state, commit}) {
            try {
                const res = await api.request.getTable(`orders/clientOfferData?OrderNum=${state.currentOrder.OrderNum}`)
                commit('SET_ALL_FLY_TO_PDF', res.data.Data)
            } catch (e) {
                throw new Error(e)
            }
        },
        async setListData({commit, state}, data) {
            try {
                switch (state.currentAutoItem) {
                    case 'country': {
                        const res = api.request.getTable(`countries?Name=${data.value}${state.listRegions[0] ? `&RegionDesc=${data.regionArray}` : ""}&limit=${data.autoLimit}`)
                        commit('SET_LIST_COUNTRIES', [...res.data.Data])
                        break;
                    }
                    case 'region': {
                        const res = await api.request.getTable(`regions?Name=${data.value}&limit=${data.autoLimit}`)
                        commit('SET_LIST_REGIONS', [...res.data.Data])
                        break;
                    }
                    case 'operator': {
                        const res = await api.request.getTable(`operators?Name=${data.value}&Country=${
                                data.requestOp.country ? data.requestOp.country : ""
                            }&Region=${
                                data.requestOp.region ? data.requestOp.region : ""
                            }&PAXFrom=${
                                data.requestOp.PAXFrom ? data.requestOp.PAXFrom : "0"
                            }&PAXTo=${
                                data.requestOp.PAXTo ? data.requestOp.PAXTo : ""
                            }&Class=${
                                data.requestOp.AircraftClass ? data.requestOp.AircraftClass : ""
                            }&limit=20`
                        )
                        commit('SET_LIST_OPERATORS', [...res.data.Data])
                        break
                    }
                    default: {
                        break;
                    }
                }
            } catch (e) {
                throw new Error(e)
            }
        },
        async emailModalOffers({state, commit}) {
            try {
                const res = await api.request.getTable(`orders/clientOfferData?OrderNum=${state.currentOrder.OrderNum}`)
                commit('SET_EMAIL_MODAL', true)
                commit('SET_CONFIRMED_LENGTH', res.data.Data.length)
            } catch (e) {
                throw new Error(e)
            }
        },
        // eslint-disable-next-line no-empty-pattern
        async saveAircraftImages({}, data) {
            try {
                const formData = new FormData()
                if (data.regNum.length > 0) {
                    if (data.files.interior.length > 0) {
                        formData.append('interior', data.files.interior[0])
                    }
                    if (data.files.exterior.length > 0) {
                        formData.append('exterior', data.files.exterior[0])
                    }
                    if (data.files.diagram.length > 0) {
                        formData.append('scheme', data.files.diagram[0])
                    }
                    formData.append('RegNum', data.regNum)
                }
                await api.request.saveAircraftImages(formData)
            } catch (e) {
                throw new Error(e)
            }
        },
        async deleteAircraftImages({state, commit}, arr) {
            try {
                switch (arr) {
                    case 'interior': {
                        await api.request.deleteAircraftImage(state.photosInterior[0].Id)
                        commit("SET_PHOTOS_INTERIOR")
                        break;
                    }
                    case 'exterior': {
                        await api.request.deleteAircraftImage(state.photosExterior[0].Id)
                        commit('SET_PHOTOS_EXTERIOR')
                        break;
                    }
                    case 'diagram': {
                        await api.request.deleteAircraftImage(state.photosDiagram[0].Id)
                        commit("SET_PHOTOS_DIAGRAM")
                        break;
                    }
                    default : {
                        break;
                    }
                }
            } catch (e) {
                throw new Error(e)
            }
        },
        async getAircraftImages({commit}, regNum) {
            try {
                commit('SET_PHOTOS_EXTERIOR')
                commit('SET_PHOTOS_INTERIOR')
                commit('SET_PHOTOS_DIAGRAM')
                if (regNum) {
                    const resp = await api.request.getOperatorImagesReq(regNum)
                    resp.data.Data && resp.data.Data.forEach(el => {
                        switch (el.Type) {
                            case 'exterior': {
                                commit('SET_PHOTOS_EXTERIOR', el)
                                break;
                            }
                            case 'interior': {
                                commit('SET_PHOTOS_INTERIOR', el)
                                break;
                            }
                            case 'scheme': {
                                commit('SET_PHOTOS_DIAGRAM', el)
                                break;
                            }
                            default: {
                                break
                            }
                        }
                    })
                }
            } catch (e) {
                throw new Error(e)
            } finally {
                commit('global/SET_LOADING', false, {root: true})
            }
        },
        async fetchFilterOrders({commit}, query) {
            const result = await api.request.getTable(`orders?${query}`);
            if (result.data.Data !== null) {
                result.data.Data.forEach((el) => {
                    if (el.StatusDesc === 'DONE') {
                        el.background = 'grey';
                        el.alphaBackground = 'grey lighten-2';
                    }
                    if (el.StatusDesc === 'PAYMENT RECIVED') {
                        el.background = 'orange';
                        el.alphaBackground = 'orange lighten-5';
                    }
                    if (el.StatusDesc === 'WAIT PAYMENT') {
                        el.background = 'red';
                        el.alphaBackground = 'red lighten-5';
                    }
                    if (el.StatusDesc === 'IN PROGRESS') {
                        el.background = 'cyan';
                        el.alphaBackground = 'cyan lighten-5';
                    }
                    if (el.StatusDesc === 'NEW') {
                        el.background = 'green';
                        el.alphaBackground = 'green lighten-5';
                    }
                    return el;
                });
            } else {
                this.$toast.warning('Данные не пришли');
                result.data.Data = [];
            }
            commit('SET_ORDERS', result.data.Data);
            commit('SET_PAGINATION', result.data.Pagination);
            // commit('SET_TEMPLATE', result.data.Template.BaseFields);
            commit('SET_TEMPLATE', {
                "Id": 0,
                "FirstName": "",
                "SecondName": "",
                "Email": "",
                "Password": "",
                "GroupID": "",
                "CompanyID": "",
                "Active": false,
                "DateCreate": "0001-01-01T00:00:00Z"
            });
        },
        async fetchOrders({commit}) {
            const result = await api.request.getTable(`orders`);
            if (result.data.Data !== null) {
                result.data.Data.forEach((el) => {
                    if (el.StatusDesc === 'DONE') {
                        el.background = 'grey';
                        el.alphaBackground = 'grey lighten-2';
                    }
                    if (el.StatusDesc === 'PAYMENT RECIVED') {
                        el.background = 'orange';
                        el.alphaBackground = 'orange lighten-5';
                    }
                    if (el.StatusDesc === 'WAIT PAYMENT') {
                        el.background = 'red';
                        el.alphaBackground = 'red lighten-5';
                    }
                    if (el.StatusDesc === 'IN PROGRESS') {
                        el.background = 'cyan';
                        el.alphaBackground = 'cyan lighten-5';
                    }
                    if (el.StatusDesc === 'NEW') {
                        el.background = 'green';
                        el.alphaBackground = 'green lighten-5';
                    }
                    return el;
                });
            } else {
                this.$toast.warning('Данные не пришли');
                // this.orders = ['no data']
            }
            const orders = result.data.Data;
            commit('SET_ORDERS', orders);
        },
        async fetchOrderTab({commit}, num) {
            try {
                const result = await api.request.getTable(`orders?OrderNum=${num}`);
                commit('SET_CURRENT_ORDER', result.data.Data[0]);
            } catch (e) {
                throw new Error(e)
            }
        },
        tabHandler({commit}, tab) {
            commit('SET_TAB', tab);
        },
        currentTabHandler({commit}, tab) {
            commit('SET_CURRENT_TAB', tab);
        },
        deleteTab({commit}, tab) {
            commit('DELETE_TAB', tab);
        },
    },
};
