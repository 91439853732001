// import api from '@/api/index.js';
// import axios from 'axios';

export default {
  namespaced: true,
  state: {
    tabs: [{ name: 'Operators', id: 0 }],
    currentTab: 0,
    currentOperator: null,
  },
  getters: {},
  mutations: {
    SET_TAB(state, payload) {
      state.tabs.push(payload);
    },
    SET_CURRENT_TAB(state, payload) {
      state.currentTab = payload;
    },
    DELETE_TAB(state, payload) {
      state.tabs = state.tabs.filter((el) => el.id !== payload);
    },
  },
  actions: {
    tabHandler({ commit }, tab) {
      commit('SET_TAB', tab);
    },
    currentTabHandler({ commit }, tab) {
      commit('SET_CURRENT_TAB', tab);
    },
    deleteTab({ commit }, tab) {
      commit('DELETE_TAB', tab);
    },
  },
};
