export default {
    namespaced: true,
    state: {
        path: 0
    },
    getters: {
        getPath: s => s.path
    },
    mutations: {
        SET_PATH: (s,v) => s.path = v,
    },
    actions: {
        tableHandler({commit}, {path}) {
            commit('SET_PATH',path)
        }
    }
}
