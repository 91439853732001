import Vue from 'vue';
import App from './App.vue';
import { router } from '@/util/router/router';
import { store } from '@/store/store';
import PluginApi from '@/plugins/api';
import ErrorApiHandler from '@/plugins/error';
import vuetify from './plugins/vuetify';
import Toast, { TYPE } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
const options = {
  toastDefaults: {
    [TYPE.ERROR]: {
      timeout: 10000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
    },
    [TYPE.SUCCESS]: {
      timeout: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
    },
  },
};

//Vue.config.productionTip = false
Vue.use(Toast, options);
Vue.use(ErrorApiHandler);
Vue.use(PluginApi);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
new Vue({
  router,
  store,
  components: { App },
  template: '<App/>',
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
