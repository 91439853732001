export default (instance) => {
    return {
        singIn: (payload) => instance.post('login', payload),
        isAuth: () => instance.get('isauth'),
        getTable: (payload) => instance.get(payload),
        get: (payload) => instance.get(payload),
        put: (payload) => instance.put(payload),
        putObj: (payload) => instance.put(payload.url, payload.data),
        createRow: (payload) => instance.post(payload.url, payload.data),
        sendOfferToClient: (id, data) => instance.post(`orders/sendOffersToClients?OrderNum=${id}`, data, {'Content-Type': 'multipart/form-data'}),
        editRow: (payload) => instance.put(payload.url, payload.data),
        deleteRow: (payload) => instance.delete(payload.url, {data: payload.data}),
        getOperatorImagesReq: (num) => instance.get(`aircrafts/images?RegNum=${num}`),
        saveAircraftImages: (data) => instance.post('aircrafts/file', data, {'Content-Type': 'multipart/form-data',}),
        deleteAircraftImage: (id) => instance.delete(`aircrafts/file?Ids=${id}`)
    };
};
