<template>
    <div class="ma-4">
        <div class="rounded-lg brd pt-4 px-3" style="max-width: 352px">
            <span class="subtitle-1 font-weight-medium ">Flight Geography Bot</span>
            <v-divider></v-divider>
            <div class="d-flex justify-space-between ">
                <v-switch class="mt-3" v-model="robotGF" label="Switch on"
                @click="setRobotGF"></v-switch>
                <v-chip v-if="!robotGF" class="mt-3" color="red lighten-5"
                    text-color="red darken-1">offline</v-chip>
                <v-chip v-else class="mt-3" color="green lighten-5"
                    text-color="green darken-2">online</v-chip>                
            </div>            
        </div> 
        <!-- Для тестов -->
        <div v-if="false" class="mt-4" style="max-width: 352px">
            <GFSelect
                operatorId="1"
                width="350px"
                placeholder="Select country"
                label="Fly regions"

                @updateCountries="onUpdateDataSelected"
            >                
            </GFSelect>   
        </div>       
        <v-btn v-if="false"
            elevation="2"
            @click="onDataShow"
        >Data selection</v-btn>
    </div>    
</template>

<script>
import GFSelect from "@/components/GFSelect";
export default {
    name: "Options",
    components:{
        GFSelect,
    },
    data: () => {
        return {
            robotGF: false,
            parent: {
                dataSelected: []
            }            
        }
    },
    mounted() {
        this.robotGFEnable()
    },
    methods: {
        async robotGFEnable() {
            try {
                const result = await this.$api.request.get(
                 "/orders/robot_gf/enabled"
                );
                console.log("robotGFEnable",result.data.Data);
                if (result.data.Data !== null) {                
                    this.robotGF = result.data.Data;
                } else {
                    console.log("robotFGEnable пустое значение");
                }
            } catch (e) {
                this.$toast.error("Error get of status robotGF");
            }  
        },
        async setRobotGF() {
            try {
                const result = await this.$api.request.put(
                    `/orders/robot_gf/enabled/${this.robotGF}`
                );
                console.log("setRobotGF",result.data);
            } catch (e) {
                    this.$toast.error("Error set of status robotGF");
            }
        },
        onUpdateDataSelected(data) {
            const tag = "onUpdateDataSelected";
            console.log(tag, "Старт ---");
            console.log(tag, "dataSelected", data);
        },
        onDataShow() {
            const tag = "onDataShow";
            console.log(tag, "Старт ---");
           
        }

    
    }
}    

</script>

<style lang="scss" scoped>
.brd {
    border: 1px solid #e0e0e0; 
}

</style>