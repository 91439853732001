<template>
  <v-main>
    <v-container class="fill-height login-background" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8">
          <v-card color="rgb(255, 255, 255, 0.8)" class="elevation-12">
            <v-window v-model="window">
              <v-window-item :value="0">
                <v-row class="fill-height">
                  <v-col class="text-center" cols="12" md="8">
                    <v-card-text class="mt-6">
                      <h1 class="text-centered display-2 cyan--text">
                        Opensky admin panel
                      </h1>
                      <v-card-actions class="justify-center">
                        <v-form>
                          <v-text-field
                            v-for="(val, key) in loginData"
                            :ref="key"
                            :label="key"
                            :placeholder="key"
                            outlined
                            :error="
                              key == 'password'
                                ? password_error
                                : username_error
                            "
                            :rules="rules[key]"
                            required
                            :key="key"
                            dense
                            :value="val"
                            class="ma-2"
                            :type="key === 'password' ? 'password' : 'text'"
                            v-model="loginData[key]"
                          />
                        </v-form>
                      </v-card-actions>
                      <v-btn
                        class="cyan white--text mb-6"
                        @click="fetchHandler"
                      >
                        Login
                      </v-btn>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="4" class="cyan">
                    <v-card-text class="white--text mt-6">
                      <h1 class="text-center display-1">Forgot password?</h1>
                    </v-card-text>
                    <v-card-actions class="justify-center mt-6">
                      <v-btn class="white" @click="window++"
                        >Help me remember</v-btn
                      >
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="1">
                <v-row class="fill-height">
                  <v-col cols="12" md="4" class="cyan">
                    <v-card-text class="white--text mt-6">
                      <h1 class="text-center display-1">
                        Remember your password?
                      </h1>
                    </v-card-text>
                    <v-card-actions class="justify-center mt-6">
                      <v-btn class="white" @click="window--">Go to login</v-btn>
                    </v-card-actions>
                  </v-col>
                  <v-col class="text-center" cols="12" md="8">
                    <v-card-text class="mt-6">
                      <h1 class="text-centered display-2 cyan--text">
                        Opensky admin panel
                      </h1>
                      <v-card-actions class="justify-center">
                        <v-form>
                          <v-text-field
                            v-for="(val, key) in loginData"
                            :label="key"
                            :placeholder="key"
                            outlined
                            :rules="rules[key]"
                            required
                            :key="key"
                            dense
                            :value="val"
                            class="ma-2"
                            :type="key === 'password' ? 'password' : 'text'"
                            v-model="loginData[key]"
                          />
                        </v-form>
                      </v-card-actions>
                      <v-btn
                        class="cyan white--text mb-6"
                        @click="fetchHandler"
                      >
                        Login
                      </v-btn>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// import custInput from '../components/customComponents/custInput';
// import axios from 'axios';npm
// import router from '../util/router'
import { mapGetters, mapActions } from "vuex";

export default {
  name: "login",
  computed: {
    ...mapGetters(["global/getToken"]),
    // text_lang(){ return this.$store.state.lang_select_pack.form_auth},
    //show_href_registration(){ return this.$store.state.registration }
  },
  data: () => {
    return {
      window: 0,
      rules: {
        username: [
          (v) => !!v || "username is required",
          (v) => /.+@.+/.test(v) || "username must be valid",
        ],
        password: [(v) => !!v || "password is required"],
      },
      loginData: {
        username:"admin@opensky.biz",
        password: "12345",
      },
      username_error: false,
      password_error: false,
    };
  },
  methods: {
    ...mapActions({
      authHandler: "global/authHandler",
    }),
    // tokenHandler(token) {
    //   try {
    //     return JSON.parse(atob(token.split(".")[1]));
    //   } catch (e) {
    //     return null;
    //   }
    // },
    async fetchHandler() {
      try {
        const result = await this.$api.request.singIn({
          email: this.loginData.username,
          password: this.loginData.password,
        });
        if (result.status === 200) {
          // const data = this.tokenHandler(result.data.token).user;
          await this.authHandler(result.data.data);
          await this.$router.push({ path: "/orders" });
        }
      } catch (error) {
        this.username_error = true;
        this.password_error = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-background {
  background: url("~@/assets/login_background.jpg");
  background-size: cover;
}
</style>


