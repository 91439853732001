import Vue from 'vue';
import Vuex from 'vuex';
import global from './global';
import table from './table';
import orders from './orders';
import operators from './operators';
import operatorCabinet from '../components/OperatorCabinet/store/operatorCabinet';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: { global, table, orders, operators, operatorCabinet},
  plugins: [createPersistedState()],
});
