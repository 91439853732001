<template>
  <v-card>
    <OperatorModal
      v-if="modal"
      :data="custOperatorModal"
      :saveModal="saveOperator"
      :cancelModal="cancelModal"
    />
    <!-- <v-dialog max-width="50%" v-model="gallery">
      <v-card class="pa-4">
        <v-card-title v-for="el in galleryData" :key="el">
          <v-img class="elevation-12" :src="el" />
        </v-card-title>
      </v-card>
    </v-dialog> -->
    <v-tabs
      :value="currentTab"
      @change="currentTabHandler"
      centered
      class="mb-0"
    >
      <v-tabs-slider />
      <v-tab v-for="el in operatorTab" :key="el.id">
        {{ el.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="currentTab"
      @change="currentTabHandler"
      :value="currentTab"
      class="order-list"
    >
      <v-tab-item>
        <Loader v-if="tableLoading" />
        <v-data-table
          v-else
          :items="table"
          :page.sync="page"
          :items-per-page="count"
          :headers="headers"

          :options.sync="options"
          class="mt-4"
          hide-default-footer
        >
          <template v-slot:item.actions="item">
            <div class="d-flex justify-space-around">
              <v-btn
                height="20"
                @click="tabHandler({ name: item.item.Name, id: item.item.Id })"
              >
                details
              </v-btn>
            </div>
          </template>
          <!-- <template v-slot:item.Images="item">
            <v-img
              contain
              max-width="80px"
              max-height="50px"
              @click="galleryHandler(item.item.Images)"
              :value="item"
              :src="item.item.Images[0]"
            />
          </template> -->
          <template v-slot:body.prepend>
            <tr>
              <v-btn
                color="blue-grey lighten-4"
                class="my-3 mx-4"
                elevation="0"
                centered
                @click="modalHandler({ type: 'create', data: template })"
                >Add row
              </v-btn>
              <td v-for="(val, key) in template" :key="'search' + key">
                <v-text-field
                  class="subheading"
                  v-model="query[key]"
                  @input="requestHandler"
                  type="text"
                  :placeholder="key"
                />
              </td>
            </tr>
          </template>
          <template v-slot:item v-if="table[0] === 'no data'">
            <tr>
              <td colspan="5">Sorry, no data</td>
            </tr>
          </template>
        </v-data-table>
        <v-row align="center" class="px-4">
          <v-col cols="0" md="2">
            <!--        <span>Showing {{table[0].Id || 0 }}-{{table[table.length - 1].Id || 0}} of {{total}}</span>-->
            <span>Showing {{ this.count }} of {{ total }}</span>
          </v-col>
          <v-col cols="12" md="8">
            <v-pagination
              v-model="page"
              :length="Math.ceil(this.total / this.limit)"
              :total-visible="9"
              @input="paginationHandler"
              class="mt-4"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              class="pt-6"
              v-model="limit"
              :items="[10, 15, 30, 50]"
              label="Items per page"
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-for="(order, i) in operatorTab.slice(1)" :key="i">
        <OperatorInfo :item="order" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/Loader";
import OperatorInfo from "@/components/OperatorInfo";
import { getFormattedDate } from "../filters";
import OperatorModal from "@/components/ModalWindows/OperatorModal";

export default {
  name: "Table",
  filters: {
    getFormattedDate
  },
  data: () => {
    return {
      options: {},
      table: [],
      tableTab: [{ title: "Operators", path: "operators" }],
      rules: {
        max(maxNum) {
          return v => (v || "").length <= maxNum || "Max exceeded";
        }
      },
      custOperatorModal: {
        data: null,
        template: null
      },
      query: {},
      data: {},
      template: {},
      page: 1,
      limit: 10,
      total: 0,
      count: 0,
      pagination: {
        orderBy: "id",
        sort: "ASC"
      },
      modal: false,
      modalData: {},
      gallery: false,
      galleryData: [],
      allStatus: null
    };
  },
  components: {
    Loader,
    OperatorInfo,
    OperatorModal
  },
  watch: {
    async watchedStates() {
      this.page = 1;
      this.query = {};
      this.setLoading(true);
      await this.requestHandler("new");
      this.setLoading(false);
    },
    query: {
      handler: function() {
        this.page = 1;
      },
      deep: true
    },
    options: {
      async handler () {
        // this.getDataFromApi()
        console.log("options", "sortBy", this.options.sortBy[0], "sortDesc", this.options.sortDesc[0])
        this.setLoading(true);
        this.requestHandler();
        this.setLoading(false);
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      const headers = [
        {
          text: "Actions",
          width: "1%",
          value: "actions",
          sortable: false,
          class: "blue-grey title lighten-4"
        }
      ];
      for (const el in this.template) {
        headers.push({
          text: el,
          value: el,
          width: `${(el.length + 18) * 7}px`,
          sortable: true,
          class: "blue-grey title lighten-4"
        });
      }
      return headers;
    },
    modalItems() {
      const obj = {};
      for (const el in this.modalData.data) {
        if (el === "Images") {
          obj[el] = 12;
        } else {
          obj[el] = 3;
        }
      }
      return obj;
    },
    tableLoading() {
      return this.getLoading();
    },
    operatorTab() {
      return this.$store.state.operators.tabs;
    },
    currentTab() {
      return this.$store.state.operators.currentTab;
    }
  },
  async mounted() {
    console.log("async mounted");
    this.setLoading(true);
    await this.requestHandler("new");
    this.setLoading(false);
  },
  methods: {
    ...mapGetters({
      getLoading: "global/getLoading"
    }),
    ...mapActions({
      setLoading: "global/setLoading",
      setTab: "operators/tabHandler",
      setCurrentTab: "operators/currentTabHandler"
    }),
    async saveOperator(type, data) {
      await this.operatorHandler({ type, data });
    },
    async operatorHandler({ type, data }) {
      try {
        if (type === "create") {
          await this.$api.request.createRow({ url: "operators", data });
        } else if (type === "edit") {
          await this.$api.request.editRow({ url: "operators", data });
        } else {
          await this.$api.request.deleteRow({
            url: "operators",
            data: { Id: data.Id }
          });
        }
        this.modal = false;
        await this.requestHandler();
        this.$toast.success("Successfully operation");
      } catch (e) {
        this.$toast.error("Error");
      }
    },
    cancelModal() {
      this.modal = false;
    },
    currentTabHandler(tab) {
      this.setCurrentTab(tab);
    },
    tabHandler(tab) {
      if (this.$store.state.operators.tabs.some(item => item.id === tab.id)) {
        return this.$store.state.operators.tabs.filter((item, key) => {
          if (item.id === tab.id) {
            this.setCurrentTab(key);
            return item;
          }
        })[0];
      }
      this.setTab(tab);
      this.setCurrentTab(this.$store.state.operators.tabs.indexOf(tab));
    },
    imgHandler({ type, data }) {
      switch (type) {
        case "delete":
          this.modalData.data.Images = this.modalData.data.Images.filter(
            img => img !== data
          );
      }
    },
    modalHandler({ type, data }) {
      // const obj = Object.assign({}, JSON.parse(JSON.stringify({ type, data })));
      // this.modalData = obj;
      // this.modal = !this.modal;
      console.log("OperatorTable - modalHandler", type, data);
      this.modal = true;
      this.custOperatorModal = Object.assign(
        {},
        JSON.parse(JSON.stringify({ type, data }))
      );
    },
    galleryHandler(data) {
      this.gallery = true;
      this.galleryData = data;
    },
    paginationHandler(val) {
      this.page = val;
      this.requestHandler();
    },
    async requestHandler(type) {
      try {
        const query = await this.queryHandler();
        const result = await this.$api.request.getTable(
          `operators?${query}&Page=${this.page}&Limit=${this.limit}`
        );
        if (result.data.Data !== null) {
          this.table = result.data.Data;
        } else {
          this.table[0] = "no data";
          this.$toast.warning("Data wasn't sent");
        }
        if (type === "new") {
          if (result.data.Template) {
            this.template = result.data.Template.BaseFields;
          }
          if (result.data.template) {
            this.template = result.data.template;
          }
        }

        if (result.data.Pagination) {
          const pagination = result.data.Pagination;
          this.page = pagination.Page;
          this.limit = pagination.Limit;
          this.count = pagination.Count;
          this.pagination.orderBy = pagination.OrderBy;
          this.pagination.sort = pagination.OrderSort;
          this.total = pagination.Total;
        }
      } catch (e) {
        this.$toast.error("Error");
      }
    },
    async rowHandler({ type, data }) {
      try {
        data["Birthdate"] = this.birthday + "T10:00:00.000000Z";
        if (data["ClientStatusId"] === 1) {
          data["ClientStatus"] = "DEFAULT";
        } else {
          data["ClientStatus"] = "VIP";
        }
        data["Id"] = +data["Id"];
        if (type === "create") {
          this.modal = !this.modal;
          await this.$api.request.createRow({ url: "operators", data });
        } else if (type === "edit") {
          this.modal = !this.modal;
          await this.$api.request.editRow({ url: "operators", data });
        } else {
          await this.$api.request.deleteRow({
            url: "operators",
            data: { Id: data.Id }
          });
        }
        await this.requestHandler();
        this.$toast.success("Successfully operation");
      } catch (e) {
        this.$toast.error("Error");
      }
    },
    queryHandler() {
      const arr = [];
      for (let i in this.query) {
        if (this.query[i] !== "") {
          arr.push(i + "=" + this.query[i]);
        } else {
          delete arr[i];
        }
      }
      arr.push("OrderBy="+this.options.sortBy[0]) 
      switch (this.options.sortDesc[0]){
        case true: arr.push("OrderSort=desc");
        break;
        case false: arr.push("OrderSort=asc");
        break;
      }
      return arr.join("&");
    }
  }
};
</script>
