export default  {
    install(Vue) {
        Vue.prototype.$auth = async (action) => {
            try {
                await action()
            } catch (error) {
                alert('что-то пошло не так')
            }
        }
    }
}