<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    localStorage.clear()
    switch (process.env.NODE_ENV) {
      case "development" :
        this.$store.commit(
            "global/SET_BASE_URL",
            "https://cabinet.openskyx.aero/api"
        );
        break;
      case "production" :
        this.$store.commit(
            "global/SET_BASE_URL",
            window.location.origin + "/api"
        );
        break;
      case "local" :
        this.$store.commit(
            "global/SET_BASE_URL",
            "https://openskyx.aero/api"
            //"https://cabinet.dev.opensky.biz/api"
        );
    }
  },
};
</script>

<style lang="scss">
@import "./style/main.scss";
@import "./assets/styles/reset.css";
@import "./assets/styles/main.scss";
</style>
