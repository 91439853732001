<template>
  <div class="tab">
    <div class="tab__header">
      SCHEDULE
    </div>
    <table class="tab__wrapper_sheet sheet">
      <thead class="sheet__header">
      <tr class="sheet__header_row">
        <th class="sheet__header_row_item"
            v-for="(v, i) in tableHeaders"
            :key="i">{{ v }}
        </th>
      </tr>
      </thead>
      <tbody class="sheet__body">
        <tr v-for="(item, idx) in tableData" class="sheet__body_row" :key="idx">
          <td class="sheet__body_row_item sheet__body_row_item_light">
            {{ idx + 1 }}
          </td>
          <td class="sheet__body_row_item sheet__body_row_item_light"
              v-if="item.DateTime && item.DateTime.length > 10">
            {{
              item.DateTime
                  | getFormattedDate({
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </td>
          <td class="sheet__body_row_item sheet__body_row_item_light" v-else>-</td>
          <td class="sheet__body_row_item sheet__body_row_item_light">{{ item.From }}
          </td>
          <td class="sheet__body_row_item sheet__body_row_item_light">{{ item.To }}
          </td>
          <td class="sheet__body_row_item sheet__body_row_item_light"
              v-if="item.DepartureLT.length > 1"> {{ item.DepartureLT }}
          </td>
          <td class="sheet__body_row_item sheet__body_row_item_light" v-else>-</td>
          <td class="sheet__body_row_item sheet__body_row_item_light"
              v-if="item.ArrivalLT.length > 1"> {{ item.ArrivalLT }}
          </td>
          <td class="sheet__body_row_item sheet__body_row_item_light" v-else>-</td>
          <td class="sheet__body_row_item sheet__body_row_item_light">{{ item.FlightTime }}
          </td>
          <td class="sheet__body_row_item sheet__body_row_item_light">{{ item.PAX }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="tab__header">
      AIRPORT INFORMATION
    </div>    
    <div class="row" style="width:100%; padding: 0px; margin: 0px"
      v-for="(item, idx) in airportList" :key="idx">
      <div class="row" style="width:100%; padding: 0 10px; margin: 0px; min-height: 26px;">
        <div class="bdot" style="width:271px; min-height: 26px;">
          <span style="font-size: 10px;">{{ item.name }}</span>
        </div>
        <div class="bdot" style="width:437px; ">
          <span style="font-size: 10px;">{{ item.info }}</span>
        </div>
      </div>                    
    </div> 
  </div>
</template>

<script>
import {getFormattedDate} from "@/filters";

export default {
  name: "VPdfFlyInfo",
  data() {
    return {

    };
  },
  filters: {getFormattedDate},
  props: {
    tableHeaders: {type: Array, required: true},
    tableData: {type: Array, required: true},
    airportList: {type: Array, required: true},
  },
  mounted() {
    console.log("mounted");
    console.log("mounted", "tableHeaders", this.tableHeaders, "tableData", this.tableData);
  },
  updated() {
    console.log("updated");
    console.log("updated", "tableHeaders", this.tableHeaders, "tableData", this.tableData);
  },
  watch: {
    tableData(newValue, oldValue) {
      console.log("watch", "newValue", newValue, "oldValue", oldValue);
    }
  }
}
</script>

<style scoped lang="scss">
table,
thead,
tbody,
tfoot,
tr,
th,
td {
  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border-collapse: inherit;
  border-spacing: 0;
  border: none inherit;
  vertical-align: inherit;
  text-align: left;
  font-weight: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}

th, td {
  display: inline;
}

.tab {
  &__header {
    @include tabHeadersPDF;
  }

  .sheet {
    width: 100%;
    //margin-bottom: 8px;

    &__header, &__body {
      &_row {
        padding: 0px 10px;
        height: 26px;
        @include flexBetweenCenter;
        border-bottom: 1px solid $secondaryDarkColor;
        width: 100%;
        text-align: left;

        &_item {
          text-align: left;
          flex: 0 0 16%;
          @include body-10-600;

          &_light {
            @include body-10-500;
          }

          &:nth-of-type(1) {
            //flex: 0 0 5%;
            flex: 0 0 31px;
          }
          &:nth-of-type(2) {
            //flex: 0 0 10%;
            flex: 0 0 81px;
          }
          &:nth-of-type(3) {
            //flex: 0 0 25%;
            flex: 0 0 159px;
          }
          &:nth-of-type(4) {
            //flex: 0 0 25%;
            flex: 0 0 158px;
          }
          &:nth-of-type(5) {
            // flex: 0 0 10%;
            flex: 0 0 67px;
          }
          &:nth-of-type(6) {
            // flex: 0 0 10%;
            flex: 0 0 67px;
          }
          &:nth-of-type(7) {
            // flex: 0 0 10%;
            flex: 0 0 96px;
          }
          &:nth-of-type(8) {
            // flex: 0 0 6%;
            flex: 0 0 40px;
          }
        }
      }
    }
  }
}

</style>
