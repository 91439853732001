var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"fixed":""}},[_c('v-row',[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"contain":"","height":"40","width":"180","src":require("@/assets/logo_opensky_title_small.png")}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-tabs',{attrs:{"centered":"","background-color":"grey lighten-4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":_vm.tab.slice(1, 15) === 'admin' ? '#E53935' : '#1976d2'}}),_vm._l((_vm.navigationTab),function(tab){return _c('v-tab',{key:tab.title,style:({
                  color: ("" + (tab.title === 'ADMIN' ? '#E53935' : '#000')),
                  display:
                    tab.title === 'ADMIN' && _vm.user.groupId !== 1
                      ? 'none'
                      : 'flex',
                  'margin-right':
                    tab.title === 'Tables' && _vm.user.groupId !== 1
                      ? 'auto'
                      : tab.title === 'ADMIN'
                      ? 'auto'
                      : '0'
                }),attrs:{"exact":"","to":tab}},[_vm._v(" "+_vm._s(tab.title)+" ")])})],2)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")])],1)]}}])},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('h3',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))]),_c('p',{staticClass:"caption mt-1"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":"","to":{ name: 'login' }},nativeOn:{"click":function($event){return _vm.logoutHandler.apply(null, arguments)}}},[_vm._v(" Logout ")])],1)])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"main"},[_c('v-container',{staticClass:"my-2",attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{attrs:{"flat":"","padless":"","fixed":"","tile":"","elevation":"12"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }