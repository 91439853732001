import Vue from "vue";
import Router from "vue-router";
import Login from "@/page/login";
import Orders from "@/page/Orders";
import MainLayout from "@/layouts/MainLayout";
import index from "@/api";
import Table from "@/page/Table";
import Admin from "@/page/Admin";
import OperatorTable from "@/page/OperatorTable";
import ConfirmPage from "@/page/ConfirmPage";
import Options from "@/page/Options";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      auth: true,
      component: MainLayout,
      children: [
        { path: "/orders", name: "Orders", component: Orders },
        { path: "/tables", name: "Tables", component: Table },
        { path: "/operators", name: "OperatorTable", component: OperatorTable },
        { path: "/admin", name: "Admin", component: Admin },
        { path: "/options", name: "Options", component: Options }
      ],
      beforeEnter: async (to, from, next) => {
        try {
          localStorage.clear()
          const result = await index.request.isAuth();
          if (result.status === 200) {
            next();
          }
        } catch (error) {
          Vue.$toast.error("Вы не авторизованы");
          router.push({ name: "login" });
        }
      }
    },
    { path: "/login", name: "login", component: Login },
    {
      path: "/operatorOffers/:token",
      name: "Operators Token",
      component: () => import("@/page/OperatorCabinet.vue")
    },
    {
      path: "/clientConfirmOffer/:token",
      name: "ConfirmOffer",
      component: ConfirmPage
    }
  ]
});

// router.beforeEach(async (to,from,next) => {
//     // const requireAuth = to.matched.some(el => el.meta.auth)
//     if (to.name !== 'login') {
//         try {
//             const result = await index.request.isAuth()
//             if (result.status === 200) {
//                 next()
//             }
//         } catch (error) {
//             alert('Вы не авторизованы')
//             next('/login')
//         }
//     } else {
//         next()
//     }
//
// });

router.afterEach(to => {
  document.title = "OpenSky | " + to.name;
});
