export function getFormattedDate(dateString, option) {
  const options = {
    year: option.year,
    month: option.month,
    day: option.day,
    hour: option.hour,
    minute: option.minute,
    second: option.second,
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const startingDate = Date.parse(dateString);
  return formatter.format(startingDate);
}
