<template>
  <div>
    <v-app-bar fixed>
      <v-row>
        <v-container fill-height fluid>
          <v-col cols="2">
            <v-img
                contain
                height="40"
                width="180"
                src="~@/assets/logo_opensky_title_small.png"
            ></v-img>
          </v-col>
        </v-container>
      </v-row>
    </v-app-bar>
    <div class="main">
      <v-container class="my-2"  v-if="msg">

        <v-row align="center" style="padding-top: 40px" >
          <v-col align="center"  >
            <div  v-if="status === 'Approved'">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
               <path style="fill:green"  d="M 24 4 c 11.028 0 20 8.972 20 20 s -8.972 20 -20 20 s -20 -8.972 -20 -20 s 8.972 -20 20 -20 z m 0 -4 c -13.254 0 -24 10.746 -24 24 s 10.746 24 24 24 s 24 -10.746 24 -24 s -10.746 -24 -24 -24 z m 12.5 17.782 l -2.842 -2.818 l -12.21 12.436 l -6.156 -5.874 l -2.792 2.872 l 9 8.638 l 15 -15.254 z"/>
              </svg>
              <v-card-text style="color: green" class="success-message">{{msg}}</v-card-text>
            </div>

            <div v-if="status === 'Error'">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <path style="fill:red"  d="M 24 4 c 11.028 0 20 8.972 20 20 s -8.972 20 -20 20 s -20 -8.972 -20 -20 s 8.972 -20 20 -20 z m 0 -4 c -13.254 0 -24 10.746 -24 24 s 10.746 24 24 24 s 24 -10.746 24 -24 s -10.746 -24 -24 -24 z m 10 31.076 l -7.184 -7.096 l 7.092 -7.174 l -2.832 -2.806 l -7.09 7.178 l -7.176 -7.086 l -2.81 2.81 l 7.186 7.104 l -7.094 7.184 l 2.81 2.81 l 7.11 -7.192 l 7.182 7.1 l 2.806 -2.832 z"/>
              </svg>
              <v-card-text style="color: red" class="success-message">{{msg}}</v-card-text>
            </div>

            <div v-if="status === 'Declined'">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" >
                <path style="fill:darkorange" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/>
              </svg>
              <v-card-text style="color: darkorange" class="success-message">{{msg}}</v-card-text>
            </div>

          </v-col>
        </v-row>

        <v-row align="center"  >
          <v-col align="center" >
            <v-card-text > Email: sales@opensky.biz </v-card-text>
            <v-card-text > Tel.: +7 700 836 81 87</v-card-text>
          </v-col>

        </v-row>

      </v-container >
      <div v-else>
        <v-divider class="mb-3" />
        <Loader />
      </div>
    </div>
    <v-footer flat padless fixed tile elevation="12">
<!--      <v-card width="100%" class="grey lighten-4 d-flex justify-space-around">-->
<!--        <v-card-text>Made by "Dimpulse"</v-card-text>-->
<!--        <v-card-text class="text-end">2021©</v-card-text>-->
<!--      </v-card>-->
    </v-footer>
  </div>
</template>

<script>
import { getFormattedDate } from "../filters";
import axios from "axios";
import Loader from "@/components/Loader";
// import Dialog from "@/components/Modal";

export default {
   components: {Loader},
  filters: {
    getFormattedDate,
  },
  data: () => ({
    sub: null,
    msg : null,
    status : "Declined"
  }),

  async created() {
    this.api = await this.apiHandler();
    this.api.put("/orders/clientConfirmOffer",JSON.stringify(this.sub)).then((resp) => {
      this.status = resp.data.Status
      this.msg = resp.data.Desc;
    });
  },

  methods: {
    async apiHandler() {
      let token = await decodeURIComponent(
          escape(window.atob(this.$route.params.token))
      );
      this.sub = await JSON.parse(JSON.parse(atob(token.split(".")[1])).sub);
      const instance = axios.create({
        baseURL: this.$store.state.global.baseURL,
      });
      instance.interceptors.request.use(
          (config) => {
            config.headers.Authorization = `Bearer ${token}`;
            return config;
          },
          (error) => Promise.reject(error)
      );
      return instance;
    },
  },

};
</script>


<style lang="scss" >

.success-message {
  font-size: 32px;
}




@media screen and (max-width: 1000px) {}

@media screen and (max-width: 700px) {}

</style>
